// NOTE: END This makes slower
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useState } from "react";

import editOkImg from "@/assets/img/ad_creator/edit_ok.svg";
import editOkHoverImg from "@/assets/img/ad_creator/edit_ok_hover.svg";
import { ReactComponent as PencilEdit } from "@/assets/img/ad_creator/pencil_edit.svg";

import { setAdCreatorStepNext, setData } from "@/redux/store";
import { LOADING_V2_MODE, setLoadingV2, setLoadingV2Mode } from "@/redux/store";

const SilboAdCreatorStep4CopiesElaborateItem = (props) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const [editHover, setEditHover] = useState(false);
  const data = useSelector((state) => state.adCreator.data);
  const session = useSelector((state) => state.adCreator.session);
  const fakeMode = useSelector((state) => state.main.fakeMode);

  const onClick = async (e) => {
    if (editMode) return;

    try {
      // Do not progagate
      e.stopPropagation();

      // Launch Loading
      dispatch(setLoadingV2(true));
      // After a while, jump to next screen
      setTimeout(() => dispatch(setAdCreatorStepNext()), 1000);

      if (fakeMode) {
        // Remove loading
        setTimeout(() => {
          dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST));
        }, 1000);
        return;
      }

      // Call selectconcept
      const dataItem = data.elaborate_insight_list[props.idx];
      const body = { ...dataItem };
      const response = await axios.post(`/selectconcept/${session}`, {
        ...body,
      });

      // Store responses
      const dataLocal = JSON.parse(JSON.stringify(data));
      dataLocal.visual_image_list.push({ ...response.data });
      dataLocal.elaborate_insight_selected =
        data.elaborate_insight_list[props.idx];
      dispatch(setData(dataLocal));
      dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST));
    } catch (e) {
      dispatch(setLoadingV2(false));
      toast.error("Request has failed");
    }
  };

  const onClickTextArea = (e) => {
    e.stopPropagation();
  };

  const onClickEdit = (e) => {
    e.stopPropagation();
    setEditMode(!editMode);
  };

  const onChange = (key, value) => {
    const dataLocal = JSON.parse(JSON.stringify(data));
    dataLocal.elaborate_insight_list[props.idx][key] = value;
    dispatch(setData(dataLocal));
  };

  const dataItem = data.elaborate_insight_list[props.idx];
  return (
    <div
      className={`w-100 p-4 ${props.className} silbo-ad-creator-copies-item ${active && editMode === false ? "active" : ""} ${editMode ? "editing" : ""}`}
      onClick={(e) => onClick(e)}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      role={editMode ? "main" : "button"}
      style={{ position: "relative" }}>
      <div className="text-end">
        <label
          className={`fw-bold insight  ${active ? "active" : ""}`}
          role="button">
          Concepto#{props.idx + 1}
        </label>
      </div>
      <div className="mt-3 fw-bold">{t("main_copy")}</div>
      <div className="mt-3 silbo-ad-creator-visuals-input">
        {editMode ? (
          <textarea
            defaultValue={dataItem.copy_principal}
            onChange={(e) => onChange("copy_principal", e.target.value)}
            onClick={(e) => onClickTextArea(e)}
            style={{ width: "100%" }}
            type="text"
          />
        ) : (
          <div>{dataItem.copy_principal}</div>
        )}
      </div>
      <div className="mt-3 fw-bold">{t("visual_narrative")}</div>
      <div className="mt-3">
        {editMode ? (
          <textarea
            defaultValue={dataItem.info_visual}
            onChange={(e) => onChange("info_visual", e.target.value)}
            onClick={(e) => onClickTextArea(e)}
            style={{ width: "100%" }}
            type="text"
          />
        ) : (
          <div>{dataItem.info_visual}</div>
        )}
      </div>
      <div className="mt-3 fw-bold">CTA</div>
      <div className="mt-3 mb-5">
        {editMode ? (
          <textarea
            defaultValue={dataItem.copy_cta}
            onChange={(e) => onChange("copy_cta", e.target.value)}
            onClick={(e) => onClickTextArea(e)}
            style={{ width: "100%" }}
            type="text"
          />
        ) : (
          <div>{dataItem.copy_cta}</div>
        )}
      </div>
      <div
        className="text-end mt-3"
        style={{ position: "absolute", bottom: "20px", right: "20px" }}>
        <div
          onClick={(e) => onClickEdit(e)}
          onMouseEnter={() => setEditHover(true)}
          onMouseLeave={() => setEditHover(false)}
          role="button"
          style={{ display: "inline-block" }}>
          <img
            alt=""
            className={editMode && editHover === false ? "" : "d-none"}
            src={editOkImg}
          />
          <img
            alt=""
            className={editMode && editHover ? "" : "d-none"}
            src={editOkHoverImg}
          />
          <PencilEdit
            className={!editMode ? "" : "d-none"}
            color="#00382E"
            height="24px"
            width="24px"
          />
        </div>
      </div>
    </div>
  );
};
export default SilboAdCreatorStep4CopiesElaborateItem;
