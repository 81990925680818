import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";

import exitIcon from "@/assets/img/exit-icon.svg";
import keyIcon from "@/assets/img/key-icon.svg";
import userImage from "@/assets/img/user.svg";

import UpdatePasswordModal from "@/components/UpdatePasswordModal/UpdatePasswordModal";

import { getLocalStorage, removeLocalStorage } from "@/utils/localstorage";

import { restoreReduxState } from "@/index";
import { useLazyGetUserQuery } from "@/service/user";

import "./UserInfo.css";

const UserInfo = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updatePasswordModal, setUpdatePasswordModal] = useState(false);

  const [getUserQuery, getUserResult] = useLazyGetUserQuery();

  useEffect(() => {
    if (getLocalStorage("token")) getUserQuery();
  }, [getUserQuery]);

  if (getUserResult.data)
    return (
      <div className="user-info-container">
        <Dropdown drop="end">
          <Dropdown.Toggle
            className="user-info"
            style={{
              position: props.isStatic ? "static" : undefined,
              boxShadow: props.isStatic ? "none" : undefined,
              border: "2px solid #F4F4F4",
              overflow: "hidden",
              ...props.style,
            }}
            variant="secondary">
            <img alt="user" className="user-image" src={userImage} />
            <div className="d-flex align-items-center gap-3">
              <div className="userinfo-text-container">
                <p className="name">{getUserResult.data?.user.name}</p>
                <p className="email">{getUserResult.data?.user.email}</p>
              </div>
              <div>
                <p className="name dots">...</p>
              </div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="menu">
            <Dropdown.Item
              as="div"
              onClick={() => setUpdatePasswordModal(true)}>
              <div className="item-container">
                <img alt="icon" src={keyIcon} />
                <p>Cambiar contraseña</p>
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              as="div"
              onClick={() => {
                dispatch(restoreReduxState());
                removeLocalStorage("token");
                navigate("/login");
              }}>
              <div className="item-container">
                <img alt="icon" src={exitIcon} />
                <p className="logout"> Log Out</p>
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <UpdatePasswordModal
          handleClose={() => setUpdatePasswordModal(false)}
          show={updatePasswordModal}
        />
      </div>
    );

  return null;
};
export default UserInfo;
