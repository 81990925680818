import progressBarCompleted from "@/assets/img/ad_creator/progress_bar_completed.svg";
import progressBarInProgress from "@/assets/img/ad_creator/progress_bar_in_progress.svg";
import progressBarPending from "@/assets/img/ad_creator/progress_bar_pending.svg";

const SilboAdCreatorStepSection = (props) => {
  const { stepNumber, stepTarget } = props;
  const isLast = props.isLast != null ? props.isLast : false;
  const isMid = props.isMid != null ? props.isMid : false;
  return (
    <div className="h-100 w-100 d-flex pt-2 pe-2">
      <div className="w-100">
        <div className="d-flex align-items-center">
          <div className="me-2">
            <img
              alt=""
              className={stepNumber > stepTarget ? "" : "d-none"}
              src={progressBarCompleted}
            />
            <img
              alt=""
              className={stepNumber === stepTarget ? "" : "d-none"}
              src={progressBarInProgress}
            />
            <img
              alt=""
              className={stepNumber < stepTarget ? "" : "d-none"}
              src={progressBarPending}
            />
          </div>

          {isLast ? null : isMid ? (
            <div className="d-flex w-100">
              <div className={`flex-grow-1 silbo-progress-bar w-50 active `} />
              <div
                className={`flex-grow-1 silbo-progress-bar w-50 ${stepNumber <= stepTarget ? "" : "active"}`}
              />
            </div>
          ) : (
            <div
              className={`flex-grow-1 silbo-progress-bar ${stepNumber <= stepTarget ? "" : "active"}`}
            />
          )}
        </div>
        <div
          className={
            stepNumber > stepTarget
              ? "fw-bold silbo-progress-text"
              : "silbo-progress-text"
          }
          style={{ width: props.textWidth ? props.textWidth : undefined }}>
          {props.title}
        </div>
      </div>
    </div>
  );
};
export default SilboAdCreatorStepSection;
