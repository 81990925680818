import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import React, { useState } from "react";

import { setLoadingV2, setSession } from "@/redux/store";

import { api } from "@/service/instance";
import { useGetUserQuery } from "@/service/user";

import "./DropFile.css";

const DragAndDropFile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getUserResult = useGetUserQuery();
  // const { data: sasToken } = useGetSasTokenQuery();

  const [dragging, setDragging] = useState(false);
  const [selectedFile, setSelectedFile] = useState(undefined);

  const handleFileSelect = (event) => {
    if (event.target.files[0]) {
      if (event.target.files[0].size > 5000000) return;
      const newFile = event.target.files;

      setSelectedFile(newFile[0]);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      console.log(e.dataTransfer.files[0].size);
      if (e.dataTransfer.files[0].size > 5000000) return;
      setSelectedFile(e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  const createSession = async () => {
    const response = await api.get("/new?character=img-generation");
    dispatch(setSession(response.data.session_id));
    return response.data.session_id;
  };

  const handleUpload = async (values) => {
    const valuesNumber = {
      left: values.left ? Number(values.left) : 0,
      right: values.right ? Number(values.right) : 0,
      top: values.top ? Number(values.top) : 0,
      bottom: values.bottom ? Number(values.bottom) : 0,
    };
    if (
      isNaN(valuesNumber.left) ||
      isNaN(valuesNumber.right) ||
      isNaN(valuesNumber.top) ||
      isNaN(valuesNumber.bottom)
    ) {
      return;
    }
    dispatch(setLoadingV2(true));

    const sessionId = await createSession();

    const uploadImageResponse = await api.post(
      `/upload-image-comfyui`,
      {
        image: selectedFile,
      },
      { headers: { "Content-Type": "multipart/form-data" } },
    );
    const subfolder = uploadImageResponse.data.subfolder;
    const name = uploadImageResponse.data.name;

    await api.post(`/outpaint-image/${sessionId}`, {
      left: valuesNumber.left ?? 0,
      right: valuesNumber.right ?? 0,
      top: valuesNumber.top ?? 0,
      bottom: valuesNumber.bottom ?? 0,
      image: `${subfolder}${name}`, //`${uploadImageResponse.data.subfolder}${uploadImageResponse.data.name}`,
    });
    dispatch(setLoadingV2(false));
    getUserResult.refetch();
    navigate(`/images/${sessionId}`);
  };

  return selectedFile ? (
    <div className="drop-file-selected-image">
      <h3>Modifica tu imagen:</h3>
      <img alt="Selected" src={URL.createObjectURL(selectedFile)} />
      <Formik
        initialValues={{
          top: undefined,
          bottom: undefined,
          left: undefined,
          right: undefined,
        }}
        onSubmit={async (values) => await handleUpload(values)}>
        {(formik) => (
          <Form>
            <div className="drop-file-selected-container">
              <div className="drop-file-selected-input-group">
                <div className="d-flex align-center gap-2">
                  <label htmlFor="arriba">Arriba</label>
                  <div className="input-container">
                    <input
                      name="top"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="0"
                      style={{
                        borderColor:
                          formik.errors.top && formik.touched.top
                            ? "red"
                            : undefined,
                      }}
                      value={formik.values.top}
                    />
                    <span>PX</span>
                  </div>
                </div>
                <div className="d-flex align-center gap-2">
                  <label htmlFor="derecha">Derecha</label>
                  <div className="input-container">
                    <input
                      name="right"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="0"
                      style={{
                        borderColor:
                          formik.errors.right && formik.touched.right
                            ? "red"
                            : undefined,
                      }}
                      value={formik.values.right}
                    />
                    <span>PX</span>
                  </div>
                </div>
              </div>
              <div className="drop-file-selected-input-group">
                <div className="d-flex align-center gap-2">
                  <label htmlFor="abajo">Abajo</label>
                  <div className="input-container">
                    <input
                      name="bottom"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="0"
                      style={{
                        borderColor:
                          formik.errors.bottom && formik.touched.bottom
                            ? "red"
                            : undefined,
                      }}
                      value={formik.values.bottom}
                    />
                    <span>PX</span>
                  </div>
                </div>

                <div className="d-flex align-center gap-2">
                  <label htmlFor="izquierda">Izquierda</label>
                  <div className="input-container">
                    <input
                      name="left"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      placeholder="0"
                      style={{
                        borderColor:
                          formik.errors.left && formik.touched.left
                            ? "red"
                            : undefined,
                      }}
                      value={formik.values.left}
                    />
                    <span>PX</span>
                  </div>
                </div>
              </div>
              <button className="drop-file-selected-button" type="submit">
                Generar
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  ) : (
    <div
      className={`drop-file-container ${dragging ? "dragging" : ""}`}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}>
      <input
        accept="image/jpg, image/jpeg, image/png"
        id="fileUpload"
        onChange={handleFileSelect}
        style={{ display: "none" }}
        type="file"
      />
      <label
        className="drop-file-container-label"
        htmlFor="fileUpload"
        style={{ cursor: "pointer" }}>
        <h5 className="plus">+</h5>
        <h5 className="drag-text">
          Drag & drop a video or image or click here to upload
        </h5>
        <div className="underline" />
        <p className="drag-alt">
          Puedes subir imágenes .png y .jpg con un peso máximo de 1 MB.
        </p>
      </label>
    </div>
  );
};

export default DragAndDropFile;
