import { useState } from "react";

import sendingImg from "@/assets/img/sending.svg";
import sendingHoverImg from "@/assets/img/sending_hover.svg";
import sendingWritingImg from "@/assets/img/sending_writing.svg";

import theme from "@/branding/theme";

import "./SilboSendButton.css";

export const SilboSendButton = (props) => {
  const [sendHover, setSendHover] = useState(false);
  const sendButton = theme().home.sendButton
    ? theme().home.sendButton.default
    : sendingWritingImg;

  const sendButtonHover = theme().home.sendButtonHover
    ? theme().home.sendButtonHover.default
    : sendingHoverImg;

  const isDisabled = !props.onClick;

  return (
    <div
      className={`send-button h-100 d-flex align-items-end justify-content-end ${isDisabled ? "disabled" : ""}`}
      onClick={(e) => {
        e.stopPropagation();
        if (props.onClick) props.onClick(e);
      }}
      onMouseEnter={() => setSendHover(true)}
      onMouseLeave={() => setSendHover(false)}
      role="button">
      {(() => {
        const mode_idle = props.active === false;
        const mode_writing = props.active && sendHover === false;
        const mode_writing_hover = props.active && sendHover === true;

        return (
          <div className="position-relative w-100">
            {sendHover && props.active ? (
              <div
                className="send-button-tooltip"
                style={{ bottom: props.activePosition || "3.5rem" }}>
                Enviar mensaje
              </div>
            ) : null}
            <img
              alt=""
              className={mode_idle ? "ms-2 me-2 send-arrow disabled" : "d-none"}
              src={sendingImg}
            />
            <img
              alt="sendButton"
              className={mode_writing ? "ms-2 me-2 send-arrow" : "d-none"}
              src={sendButton}
            />
            <img
              alt="hoverButton"
              className={
                mode_writing_hover
                  ? `ms-2 me-2 ${theme().home.sendButtonHover ? "send-arrow" : "send-arrow-hover"}`
                  : "d-none"
              }
              src={sendButtonHover}
            />
          </div>
        );
      })()}
    </div>
  );
};
