import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useEffect, useRef, useState } from "react";

import securitasLoadingLogo from "@/assets/img/Loading_SD.png";
import mahouLoadingLogo from "@/assets/img/Loading_mahou.png";
import silboLoadingLogo from "@/assets/img/silbo_loading_logo.gif";

import { useTimeout } from "@/hooks/useTimeout";

import {
  AD_CREATOR_STEP,
  LOADING_V2_MODE,
  setLoadingV2,
  stepOrder,
} from "@/redux/store";

import theme from "@/branding/theme";

import "./LoadingOverlay.css";

const LoadingOverlayV2 = () => {
  const { t } = useTranslation();
  const [transitionStart, setTransitionStart] = useState(false);
  const adCreatorStepState = useSelector((state) => state.adCreator.step);
  const loadingV2Mode = useSelector((state) => state.main.loadingV2Mode);
  const isForRegeneration = useSelector(
    (state) => state.main.isForRegeneration,
  );
  const stepNumber = useSelector((state) => state.adCreator.stepNumber);
  const loadingText = useRef("");
  const dispatch = useDispatch();

  // Loading mode
  const EXPECTED_DURATION_SLOW = 7 * 60 * 1000;
  const EXPECTED_DURATION_FAST = 3 * 1000;
  // const EXPECTED_DURATION_FAST = 1000;
  const EXPECTED_DURATION =
    loadingV2Mode === LOADING_V2_MODE.FAST
      ? EXPECTED_DURATION_FAST
      : EXPECTED_DURATION_SLOW;

  const MAX_PROGRESS = 100; // max  [%]
  const STEP_PROGRESS = 1; // step [%]
  const [progress, setProgress] = useState(10); // current progress [%]

  // Compute secs per step
  const N_STEPS = (MAX_PROGRESS - 10) / STEP_PROGRESS;
  const secsPerStep =
    progress < MAX_PROGRESS ? EXPECTED_DURATION / N_STEPS : null;

  const adCreatorStep = isForRegeneration
    ? stepOrder[stepNumber - 1]
    : adCreatorStepState;

  useEffect(() => {
    setTransitionStart(true);

    // Compute text
    if (adCreatorStep === AD_CREATOR_STEP.STEP_1_WELCOME)
      loadingText.current = t("step_1_loading");
    if (adCreatorStep === AD_CREATOR_STEP.STEP_3_BRIEFING)
      loadingText.current = t("step_3_loading");
    if (adCreatorStep === AD_CREATOR_STEP.STEP_4_COPIES)
      loadingText.current = t("step_4_copies_loading");
    if (adCreatorStep === AD_CREATOR_STEP.STEP_4_COPIES_ELABORATE)
      loadingText.current = t("step_4_copies_ellaborate_loading");
    if (adCreatorStep === AD_CREATOR_STEP.STEP_5_VISUALS)
      loadingText.current = t("step_5_visuals_loading");
    if (adCreatorStep === AD_CREATOR_STEP.STEP_6_CREATIVITIES)
      loadingText.current = t("step_7_export_loading");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useTimeout(
    () => {
      setProgress(progress + STEP_PROGRESS);
      if (progress + STEP_PROGRESS >= 100) {
        // Give a little bit for the user to see that this reached 100%
        setTimeout(() => dispatch(setLoadingV2(false)), 1000);
      }
    },
    secsPerStep,
    progress,
  );
  const imageLoading =
    theme().login.case === "Mahou"
      ? mahouLoadingLogo
      : theme().login.case === "Securitas"
        ? securitasLoadingLogo
        : silboLoadingLogo;
  return (
    <div className={`silbo-loading-overlay ${transitionStart ? "show" : ""}`}>
      <div className="container d-flex align-items-center w-100 h-100 flex-column">
        <div
          className="text-center content"
          style={{
            minWidth: "50%",
          }}>
          <img alt="" src={imageLoading} />
          <div
            className="position-relative w-100 loading-bar"
            style={{
              height: "25px",
              borderRadius: "20px",
              marginTop: "5rem",
            }}>
            <div
              className="loaded-bar position-absolute "
              style={{
                height: "25px",
                borderRadius: "20px",
                width: `${progress}%`,
              }}
            />
          </div>
          <div className="mt-5">{progress}%</div>
          <div>{loadingText.current}</div>
        </div>
      </div>
    </div>
  );
};
export default LoadingOverlayV2;
