import axios from "axios";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useEffect, useRef, useState } from "react";

import SilboAdCreatorFooter from "@/components/Ad_creator/Footer";
import { SilboAdCreatorStepBar } from "@/components/Ad_creator/StepBar";

import { useInterval } from "@/hooks/useInterval";

import {
  AD_CREATOR_STEP,
  LOADING_V2_MODE,
  setAdCreatorStepNext,
  setData,
  setLoadingV2,
  setLoadingV2Mode,
} from "@/redux/store";

export const SilboAdCreatorStep3Briefing = (props) => {
  const dropdownRef = useRef(null);
  const [width, setWidth] = useState(undefined);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const session = useSelector((state) => state.adCreator.session);
  const data = useSelector((state) => state.adCreator.data);
  const [intervalTimeout, setIntervalTimeout] = useState(null);
  const step = useSelector((state) => state.adCreator.step);
  const fakeMode = useSelector((state) => state.main.fakeMode);
  const intervalPeriodForTask = useSelector(
    (state) => state.main.intervalPeriodForTask,
  );

  const isBtnEnabled = () =>
    data.briefing.product.length > 0 &&
    data.briefing.audience.length > 0 &&
    data.briefing.ad_objective.length > 0 &&
    data.briefing.price.length > 0;

  const onClick = async () => {
    if (isBtnEnabled() === false) return;
    try {
      // Launch Loading
      dispatch(setLoadingV2(true));
      // // After a while, jump to next screen
      // setTimeout( () => dispatch(setAdCreatorStepNext()), 1000)

      if (fakeMode) {
        // Remove loading
        setTimeout(() => {
          dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST));
        }, 1000);
        // After a while, jump to next screen
        setTimeout(() => dispatch(setAdCreatorStepNext()), 1000);
        return;
      }

      // Proceed with axios
      const body = {
        profile: data.profile,
        product: data.briefing.product,
        price: data.briefing.price,
        audience: data.briefing.audience,
        campaign_url: data.briefing.campaign_url,
        additional_info: data.briefing.additional_info,
        ad_objective: data.briefing.ad_objective,
      };
      await axios.post(
        `/getinsights/${session}?runstatus=run&regenerate=false`,
        { ...body },
      );
      setIntervalTimeout(intervalPeriodForTask);
    } catch (e) {
      toast.error("Request has failed");
      console.error(e);
    }
  };

  useEffect(() => {
    const updateWidth = () => {
      if (dropdownRef.current) {
        setWidth(dropdownRef.current.offsetWidth - 2.5);
      }
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  useInterval(async () => {
    try {
      const response = await axios.post(
        `/getinsights/${session}?runstatus=retrieve&regenerate=false`,
      );
      if ("status" in response.data) {
        if (response.data.status === "failed") {
          toast.error("Request has failed");
          dispatch(setLoadingV2(false));
          setIntervalTimeout(null);
        }
        return;
      }
      // NOTE: This might happen because request is taking too long
      if (step !== AD_CREATOR_STEP.STEP_3_BRIEFING) return;

      // Store responses
      const dataLocal = JSON.parse(JSON.stringify(data));
      dataLocal.insight_list = [
        response.data.insight1,
        response.data.insight2,
        response.data.insight3,
      ];
      dispatch(setData(dataLocal));
      //dispatch(setLoadingV2(false))
      dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST));
      setIntervalTimeout(null);
      // Give a minute to stop interval
      setTimeout(() => dispatch(setAdCreatorStepNext()), 300);
    } catch (e) {
      toast.error("Request has failed");
      dispatch(setLoadingV2(false));
      setIntervalTimeout(null);
      console.error(e);
      console.log(e.response.data);
    }
  }, intervalTimeout);

  return (
    <div className="d-flex flex-column silbo-ad-creator-container">
      <SilboAdCreatorStepBar step={props.step} />
      <div
        className="mt-2 flex-grow-1 bg-white d-flex flex-column"
        style={{ borderRadius: "10px", padding: "2em 3em" }}>
        <div className="fw-bold" style={{ fontSize: "32px" }}>
          {t("step_3_briefing_large_title")}
        </div>
        <div
          className="mt-3 flex-grow-1 d-flex flex-column"
          style={{ padding: "0 15%" }}>
          <div
            className="d-flex flex-grow-1 w-100"
            style={{ margin: "0 auto" }}>
            <div className="w-50 me-1 h-100 d-flex flex-column justify-content-center">
              <div className="p-4 silbo-ad-creator-input h-30 d-flex flex-column justify-content-center">
                <div className="fw-bold mb-2">{t("product_service")}*</div>
                <input
                  onChange={(e) =>
                    dispatch(
                      setData({
                        ...data,
                        briefing: { ...data.briefing, product: e.target.value },
                      }),
                    )
                  }
                  placeholder={t("placeholder_product_service")}
                  type="text"
                />
              </div>
              <div className="h-30 w-100 my-2 two-items">
                <div className="p-4 silbo-ad-creator-input  d-flex flex-column justify-content-center">
                  <div className="fw-bold mb-2">{t("audience")}*</div>
                  <input
                    onChange={(e) =>
                      dispatch(
                        setData({
                          ...data,
                          briefing: {
                            ...data.briefing,
                            audience: e.target.value,
                          },
                        }),
                      )
                    }
                    placeholder={t("placeholder_audience")}
                    type="text"
                  />
                </div>
                <div className="p-4 silbo-ad-creator-input d-flex flex-column justify-content-center">
                  <div className="fw-bold mb-2">{t("price_per_month")}*</div>
                  <input
                    onChange={(e) =>
                      dispatch(
                        setData({
                          ...data,
                          briefing: { ...data.briefing, price: e.target.value },
                        }),
                      )
                    }
                    placeholder={t("placeholder_price")}
                    type="text"
                  />
                </div>
              </div>
              <div className="p-4 mt-1 silbo-ad-creator-input h-30 d-flex flex-column justify-content-center">
                <div className="fw-bold mb-2">{t("destination_url")}</div>
                <input
                  onChange={(e) =>
                    dispatch(
                      setData({
                        ...data,
                        briefing: {
                          ...data.briefing,
                          campaign_url: e.target.value,
                        },
                      }),
                    )
                  }
                  placeholder={t("placeholder_url")}
                  type="text"
                />
              </div>
            </div>

            <div className="w-50 ms-1 h-100 d-flex flex-column justify-content-center">
              <div className="p-4 silbo-ad-creator-input h-60 d-flex flex-column justify-content-center">
                <div className="fw-bold mb-2">
                  {t("additional_information")}
                </div>
                <textarea
                  className="h-100"
                  onChange={(e) =>
                    dispatch(
                      setData({
                        ...data,
                        briefing: {
                          ...data.briefing,
                          additional_info: e.target.value,
                        },
                      }),
                    )
                  }
                  placeholder={t("placeholder_additional_information")}
                  type="text"
                />
              </div>
              <div className="p-4 mt-2 silbo-ad-creator-input h-30 d-flex flex-column justify-content-center">
                <div className="fw-bold mb-2">{t("campaign_objective")}*</div>
                <DropdownButton
                  className="new-form-select"
                  drop="below"
                  ref={dropdownRef}
                  title={data.briefing.ad_objective ?? "&nbsp;"}
                  variant="light">
                  <Dropdown.Item
                    onClick={() =>
                      dispatch(
                        setData({
                          ...data,
                          briefing: {
                            ...data.briefing,
                            ad_objective: t("option_awareness"),
                          },
                        }),
                      )
                    }
                    style={{ width }}
                    value={t("option_awareness")}>
                    {t("option_awareness")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      dispatch(
                        setData({
                          ...data,
                          briefing: {
                            ...data.briefing,
                            ad_objective: t("option_engagement"),
                          },
                        }),
                      )
                    }
                    value={t("option_engagement")}>
                    {t("option_engagement")}
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() =>
                      dispatch(
                        setData({
                          ...data,
                          briefing: {
                            ...data.briefing,
                            ad_objective: t("option_conversion"),
                          },
                        }),
                      )
                    }
                    value={t("option_conversion")}>
                    {t("option_conversion")}
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <div
              className={`silbo-button ${isBtnEnabled() ? "" : "disabled"} w-50`}
              onClick={() => onClick()}>
              {t("generate")}
            </div>
          </div>
        </div>
        <div className="mt-3">
          <SilboAdCreatorFooter />
        </div>
      </div>
    </div>
  );
};
