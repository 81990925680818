import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";

import SilboAdCreatorFooter from "@/components/Ad_creator/Footer";
import { SilboAdCreatorStepBar } from "@/components/Ad_creator/StepBar";

import { setAdCreatorStepNext } from "@/redux/store";

import { SILBO_MODELS } from "@/utils";

export const SilboAdCreatorStep7Export = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.adCreator.data);
  const fakeMode = useSelector((state) => state.main.fakeMode);
  const [isLoading, setIsLoading] = useState(true);
  const [imgs, setImgs] = useState({
    "980x250": "",
    "300x600": "",
    "300x250": "",
    "970x250": "",
    "1080x1920": "",
    "120x600": "",
    "160x600": "",
    "728x900": "",
    "420x900": "",
    "1080x1080": "",
    "1024x512": "",
    "512x1024": "",
    "728x90": "",
    "320x100": "",
  });

  const getMeta = (url) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (err) => reject(err);
      img.src = url;
    });

  useEffect(() => {
    setIsLoading(true);
    const images = {};

    if (data?.download_urls_final?.length > 0) {
      data.download_urls_final.forEach(async (image) => {
        const dimensions = await getMeta(image);
        if (dimensions?.naturalWidth && dimensions?.naturalHeight) {
          images[dimensions.naturalWidth + "x" + dimensions.naturalHeight] =
            dimensions.src;
        }
      });
      setImgs(images);
      setIsLoading(false);
    }
  }, [data.download_urls_final, data]);

  const onDownload = () => {
    // Download to local machine
    const link = document.createElement("a");
    link.href = fakeMode
      ? "https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf"
      : data.zip;
    link.setAttribute("download", "export.zip"); //or any other extension
    document.body.appendChild(link);
    link.click();

    // Thank you screen
    dispatch(setAdCreatorStepNext());
  };

  const handleEditClick = () => {
    const getUrl = () => {
      switch (data.model) {
        case SILBO_MODELS.STABLE_DIFUSION:
          return "https://app.creatopy.com/team/1068996/project/2444501/designs/folder/1513231";
        case SILBO_MODELS.COMPANIES:
          return "https://app.creatopy.com/team/1068996/project/2444501/designs/folder/1512903";
        case SILBO_MODELS.USERS:
          return "https://app.creatopy.com/team/1068996/project/2444501/designs/folder/1513480";
        default:
          return "";
      }
    };
    window.open(getUrl(), "_blank");
  };

  return (
    <div className="d-flex flex-column silbo-ad-creator-container">
      <SilboAdCreatorStepBar step={props.step} />
      <div
        className="mt-2 flex-grow-1 bg-white d-flex flex-column"
        style={{ borderRadius: "10px", padding: "2em 3em 3.5em 3em" }}>
        <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
          <div
            className="fw-bold text-center w-75"
            style={{ fontSize: "32px", margin: "0 auto" }}>
            {t("step_7_export_large_title")}
          </div>
          <div className="mt-4 w-75 flex-grow-1 d-flex flex-column">
            <div className="flex-grow-1" style={{ margin: "0 auto" }}>
              <div style={{ margin: "0 auto" }}>
                {!isLoading ? (
                  <div className="d-flex flex-wrap flex-column justify-content-between align-items-start gap-3">
                    <img alt="" className="mw-100" src={imgs["320x100"]} />
                    <img alt="" className="mw-100" src={imgs["728x90"]} />
                    <img alt="" className="mw-100" src={imgs["970x250"]} />
                    <img alt="" className="mw-100" src={imgs["980x250"]} />
                    <img alt="" className="mw-100" src={imgs["1024x512"]} />
                    <div className="d-flex flex-wrap justify-content-between align-items-start gap-3">
                      <img
                        alt=""
                        src={imgs["300x600"]}
                        style={{ maxWidth: "20%" }}
                      />
                      <img
                        alt=""
                        src={imgs["1080x1920"]}
                        style={{ maxWidth: "25%" }}
                      />
                      <img
                        alt=""
                        src={imgs["512x1024"]}
                        style={{ maxWidth: "25%" }}
                      />
                      <img
                        alt=""
                        src={imgs["420x900"]}
                        style={{ maxWidth: "25%" }}
                      />
                    </div>
                    <div className="d-flex flex-wrap justify-content-between align-items-start gap-3">
                      <div
                        className="d-flex flex-column gap-3"
                        style={{ maxWidth: "25%" }}>
                        <img alt="" className="mw-100" src={imgs["728x900"]} />
                      </div>
                      <div
                        className="d-flex flex-column gap-3"
                        style={{ maxWidth: "25%" }}>
                        <img alt="" className="mw-100" src={imgs["300x250"]} />
                        <img
                          alt=""
                          className="mw-100"
                          src={imgs["1080x1080"]}
                        />
                      </div>
                      <img
                        alt=""
                        src={imgs["120x600"]}
                        style={{ maxWidth: "20%" }}
                      />
                      <img
                        alt=""
                        src={imgs["160x600"]}
                        style={{ maxWidth: "20%" }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            bottom: "0px",
            width: "calc(100vw - 54px * 2)",
            backgroundColor: "#fff",
            left: "48px",
          }}>
          <div style={{ padding: "8px 48px" }}>
            <SilboAdCreatorFooter
              onDownload={() => onDownload()}
              onEdit={() => handleEditClick()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
