import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import VimeoPlayer from "react-player/vimeo";

import { useState } from "react";

export const VideoPlayer = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { t } = useTranslation();

  return (
    <>
      <Button className="home-main-content-button" onClick={handleShow}>
        {t("main-content-button")}
      </Button>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={handleClose}
        show={show}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t("main-content-button")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <VimeoPlayer
            className="bg-dark overflow-hidden"
            controls={true}
            height="50vh"
            muted={true}
            playing={true}
            url="https://player.vimeo.com/video/980601684?h=3745cbe8f2"
            width="100%"
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
