const getSideBarDate = (date) => {
  const currentDate = new Date();
  const targetDate = new Date(date);

  const options = { weekday: "long", month: "long", day: "numeric" };
  const formattedDate = targetDate.toLocaleDateString("es-ES", options);

  if (
    targetDate.toISOString().split("T")[0] ===
    currentDate.toISOString().split("T")[0]
  ) {
    return "Hoy";
  } else if (
    targetDate.toISOString().split("T")[0] ===
    new Date(currentDate.setDate(currentDate.getDate() - 1))
      .toISOString()
      .split("T")[0]
  ) {
    return "Ayer";
  } else {
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
  }
};

export default getSideBarDate;
