// NOTE: END This makes slower
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useState } from "react";

import SilboAdCreatorFooter from "@/components/Ad_creator/Footer";
import { SilboAdCreatorStepBar } from "@/components/Ad_creator/StepBar";

import { useInterval } from "@/hooks/useInterval";

import SilboAdCreatorStep4CopiesElaborateItem from "@/pages/Ad_creator/SilboAdCreatorStep4Copies/SilboAdCreatorStep4CopiesElaborateItem";

import { AD_CREATOR_STEP, setData, setIsForRegeneration } from "@/redux/store";
import { LOADING_V2_MODE, setLoadingV2, setLoadingV2Mode } from "@/redux/store";

const SilboAdCreatorStep4CopiesElaborate = (props) => {
  const { t } = useTranslation();
  const data = useSelector((state) => state.adCreator.data);
  const step = useSelector((state) => state.adCreator.step);
  const session = useSelector((state) => state.adCreator.session);
  const dispatch = useDispatch();
  const [intervalRegenerate, setIntervalRegenerate] = useState(null);
  const intervalPeriodForTask = useSelector(
    (state) => state.main.intervalPeriodForTask,
  );

  const onRegenerate = async () => {
    try {
      // Launch Loading
      dispatch(setIsForRegeneration(true));
      dispatch(setLoadingV2(true));

      // Proceed with axios
      const body = { insight: data.insight_selected };
      await axios.post(
        `/elaborateinsight/${session}?runstatus=run&regenerate=false`,
        { ...body },
      );
      setIntervalRegenerate(intervalPeriodForTask);
    } catch (e) {
      console.error(e);
    }
  };

  useInterval(async () => {
    try {
      const response = await axios.post(
        `/elaborateinsight/${session}?runstatus=retrieve&regenerate=false`,
      );
      if ("status" in response.data) {
        if (response.data.status === "failed") {
          toast.error("Request has failed");
          dispatch(setLoadingV2(false));
          setIntervalRegenerate(null);
        }
        return;
      }
      // NOTE: This might happen because request is taking too long
      if (step !== AD_CREATOR_STEP.STEP_4_COPIES_ELABORATE) return;

      // Store responses
      const dataLocal = JSON.parse(JSON.stringify(data));
      dataLocal.elaborate_insight_list = response.data;
      dispatch(setData(dataLocal));
      //dispatch(setLoadingV2(false))
      dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST));
      setIntervalRegenerate(null);
    } catch (e) {
      console.error(e);
    } finally {
      dispatch(setIsForRegeneration(false));
    }
  }, intervalRegenerate);

  return (
    <div className="d-flex flex-column silbo-ad-creator-container">
      <SilboAdCreatorStepBar step={props.step} />
      <div
        className="mt-2 flex-grow-1 bg-white d-flex flex-column"
        style={{ borderRadius: "10px", padding: "2em 3em" }}>
        <div>
          <div
            className="fw-bold text-center w-75"
            style={{ fontSize: "32px", margin: "0 auto" }}>
            {t("step_4_copies_mid_large_title")}
          </div>
          <div
            className="fw-bold text-center w-75"
            style={{ fontSize: "32px", margin: "0 auto" }}>
            {t("step_4_copies_large_title")}
          </div>
          <div
            className="mt-4 d-flex justify-content-between w-75 flex-grow-1"
            style={{ margin: "0 auto" }}>
            <SilboAdCreatorStep4CopiesElaborateItem idx={0} />
            <SilboAdCreatorStep4CopiesElaborateItem
              className="ms-2 me-3"
              idx={1}
            />
            <SilboAdCreatorStep4CopiesElaborateItem idx={2} />
          </div>
        </div>
        <div className="mt-3">
          <SilboAdCreatorFooter onRegenerate={() => onRegenerate()} />
        </div>
      </div>
    </div>
  );
};
export default SilboAdCreatorStep4CopiesElaborate;
