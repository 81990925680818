import { Card } from "react-bootstrap";

import { ReactComponent as Check } from "@/assets/img/check.svg";

import "./ProductAssistant.css";

const ProductBodegon = ({ product, selectedProduct, setSelectedProduct }) => (
  <Card
    className={`bodegon-card ${selectedProduct?.id === product.id ? "selected" : ""}`}
    onClick={() => {
      setSelectedProduct(product);
    }}>
    <div
      className={`bodegon-check ${selectedProduct?.id === product.id ? "show" : "hidden"}`}>
      <Check />
    </div>
    <img
      alt={product.name}
      className="bodegon-card-image"
      src={product.image}
    />
  </Card>
);

export default ProductBodegon;
