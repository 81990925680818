import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useState } from "react";

import SilboAdCreatorFooter from "@/components/Ad_creator/Footer";
import { SilboAdCreatorStepBar } from "@/components/Ad_creator/StepBar";

import { useInterval } from "@/hooks/useInterval";

import SilboAdCreatorStep4CopiesItem from "@/pages/Ad_creator/SilboAdCreatorStep4Copies/CopiesItem";

import {
  AD_CREATOR_STEP,
  setAdCreatorStepNext,
  setData,
  setIsForRegeneration,
} from "@/redux/store";
import { LOADING_V2_MODE, setLoadingV2, setLoadingV2Mode } from "@/redux/store";

export const SilboAdCreatorStep4Copies = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const session = useSelector((state) => state.adCreator.session);
  const data = useSelector((state) => state.adCreator.data);
  const step = useSelector((state) => state.adCreator.step);
  const [intervalTimeout, setIntervalTimeout] = useState(null);
  const [intervalRegenerate, setIntervalRegenerate] = useState(null);
  const fakeMode = useSelector((state) => state.main.fakeMode);
  const intervalPeriodForTask = useSelector(
    (state) => state.main.intervalPeriodForTask,
  );

  // --------------------
  // Handlers
  // --------------------

  const onClick = async (insight_selected) => {
    try {
      // Launch Loading
      dispatch(setLoadingV2(true));
      // After a while, jump to next screen
      //setTimeout( () => dispatch(setAdCreatorStepNext()), 1000)

      if (fakeMode) {
        // Remove loading
        setTimeout(
          () => dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST)),
          1000,
        );
        setTimeout(() => dispatch(setAdCreatorStepNext({ isMid: true })), 100);
        return;
      }

      // Proceed with axios
      const body = { insight: insight_selected };
      await axios.post(`/elaborateinsight/${session}`, {
        ...body,
      });
      setIntervalTimeout(intervalPeriodForTask);

      // Store responses
      const dataLocal = JSON.parse(JSON.stringify(data));
      dataLocal.insight_selected = insight_selected;
      dispatch(setData(dataLocal));
    } catch (e) {
      toast.error("Request has failed");
      console.error(e);
    }
  };

  const onRegenerate = async () => {
    try {
      // Launch Loading
      dispatch(setLoadingV2(true));
      dispatch(setIsForRegeneration(true));
      // // After a while, jump to next screen
      // setTimeout( () => dispatch(setAdCreatorStepNext()), 1000)

      if (fakeMode) {
        // Remove loading
        setTimeout(
          () => dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST)),
          1000,
        );
        return;
      }

      // Proceed with axios
      const body = {
        profile: data.profile,
        product: data.briefing.product,
        audience: data.briefing.audience,
        campaign_url: data.briefing.campaign_url,
        additional_info: data.briefing.additional_info,
        ad_objective: data.briefing.ad_objective,
      };
      await axios.post(
        `/getinsights/${session}?runstatus=run&regenerate=true`,
        { ...body },
      );
      setIntervalRegenerate(intervalPeriodForTask);
    } catch (e) {
      toast.error("Request has failed");
      dispatch(setLoadingV2(true));
      console.error(e);
    }
  };

  // --------------------
  // Hooks
  // --------------------

  useInterval(async () => {
    try {
      const response = await axios.post(
        `/elaborateinsight/${session}?runstatus=retrieve&regenerate=false`,
      );
      if ("status" in response.data) {
        console.log(
          `Request 'elaborateinsight' status='${response.data.status}'`,
        );
        if (response.data.status === "failed") {
          toast.error("Request has failed");
          dispatch(setLoadingV2(false));
          setIntervalTimeout(null);
        }
        return;
      }
      // NOTE: This might happen because request is taking too long
      if (step !== AD_CREATOR_STEP.STEP_4_COPIES) return;

      // Store responses
      const dataLocal = JSON.parse(JSON.stringify(data));
      dataLocal.elaborate_insight_list = response.data;
      dispatch(setData(dataLocal));
      //dispatch(setLoadingV2(false))
      dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST));
      setIntervalTimeout(null);
      // Give a minute to stop interval
      setTimeout(() => dispatch(setAdCreatorStepNext({ isMid: true })), 100);
    } catch (e) {
      toast.error("Request has failed");
      console.error(e);
    }
  }, intervalTimeout);

  useInterval(async () => {
    try {
      const response = await axios.post(
        `/getinsights/${session}?runstatus=retrieve&regenerate=false`,
      );
      if ("status" in response.data) {
        if (response.data.status === "failed") {
          toast.error("Request has failed");
          dispatch(setLoadingV2(false));
          setIntervalTimeout(null);
        }
        return;
      }
      // NOTE: This might happen because request is taking too long
      if (step !== AD_CREATOR_STEP.STEP_4_COPIES) return;

      // Store responses
      const dataLocal = JSON.parse(JSON.stringify(data));
      dataLocal.insight_list = [
        response.data.insight1,
        response.data.insight2,
        response.data.insight3,
      ];
      dispatch(setData(dataLocal));
      //dispatch(setLoadingV2(false))
      dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST));
      setIntervalRegenerate(null);
    } catch (e) {
      toast.error("Request has failed");
      dispatch(setLoadingV2(false));
      setIntervalTimeout(null);
      console.error(e);
    } finally {
      dispatch(setIsForRegeneration(false));
    }
  }, intervalRegenerate);

  const { insight_list } = data;
  return (
    <div className="d-flex flex-column silbo-ad-creator-container">
      <SilboAdCreatorStepBar step={props.step} />
      <div
        className="mt-2 flex-grow-1 bg-white d-flex flex-column"
        style={{ borderRadius: "10px", padding: "2em 3em" }}>
        <div className="d-flex flex-grow-1 flex-column">
          <div>
            <div
              className="fw-bold text-center w-75"
              style={{ fontSize: "32px", margin: "0 auto" }}>
              {t("step_4_copies_mid_title")}
            </div>
            <div
              className="fw-bold text-center w-75"
              style={{ fontSize: "32px", margin: "0 auto" }}>
              {t("step_4_copies_mid_large")}
            </div>
            <div
              className="mt-4 d-flex justify-content-between w-75 flex-grow-1"
              style={{ margin: "0 auto" }}>
              <SilboAdCreatorStep4CopiesItem
                data={insight_list[0]}
                idx={0}
                onClick={() => onClick(insight_list[0])}
              />
              <SilboAdCreatorStep4CopiesItem
                className="ms-2 me-3"
                data={insight_list[1]}
                idx={1}
                onClick={() => onClick(insight_list[0])}
              />
              <SilboAdCreatorStep4CopiesItem
                data={insight_list[2]}
                idx={2}
                onClick={() => onClick(insight_list[0])}
              />
            </div>
          </div>
        </div>
        <div className="mt-3">
          <SilboAdCreatorFooter onRegenerate={() => onRegenerate()} />
        </div>
      </div>
    </div>
  );
};
