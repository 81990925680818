import { Formik, Form as FormikForm } from "formik";
import Markdown from "https://esm.sh/react-markdown@9";
import Lottie from "lottie-react";
import { Dropdown, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useEffect, useRef, useState } from "react";

import { ReactComponent as FileIcon } from "@/assets/img/File.svg";
import generatingTextImage from "@/assets/img/generating-text.json";
import HistoryImage from "@/assets/img/history.svg";
import pencilImage from "@/assets/img/pencil.svg";
import { ReactComponent as PromptLibraryImage } from "@/assets/img/prompt-library.svg";
import securitasLogo from "@/assets/img/securitas_logo.svg";
import pencilIcon from "@/assets/img/sidebar-pencil.svg";
// Images
import silboLogo from "@/assets/img/silbo_logo_v2.svg";
import superRealMinLogo from "@/assets/img/sr_logo_min_v2.svg";
import trashImage from "@/assets/img/trash.svg";

import HistorySideBar from "@/components/HistorySideBar/HistorySideBar";
import { PromptManager } from "@/components/PromptLibrary";
import { SilboSendButton } from "@/components/SilboSendButton";
import Suggestion from "@/components/Suggestion";
import UserInfo from "@/components/UserInfo/UserInfo";

import { setMaxWidth, setSession } from "@/redux/store";

import { maxWidthValue, minWidthValue } from "@/utils/sidebar";
import getSideBarDate from "@/utils/sidebar-date";

import theme from "@/branding/theme";
import { api } from "@/service/instance";
import {
  useChangeTextSessionNameMutation,
  useDeleteSessionMutation,
  useGetUserQuery,
  useLazyGetSessionQuery,
} from "@/service/user";
import {
  SILBO_PROFILES,
  getProfileLiterals,
  isDesktop,
  isMobile,
} from "@/utils";

import "./SilboTextAssistant.css";
import UploadedFileElement from "./UploadedFile";

const AUTHOR = { ME: "ME", BOT: "BOT" };

export const SilboTextAssistant = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const getUserResult = useGetUserQuery();
  const [getTextSessionQuery, getTextSessionResult] = useLazyGetSessionQuery();
  const [deleteSessionById, { isSuccess, reset, isLoading: isDeleting }] =
    useDeleteSessionMutation();
  const [
    changeTextSessionName,
    {
      isSuccess: changeTextIsSuccess,
      reset: changeTextReset,
      isLoading: changeTextIsChangin,
    },
  ] = useChangeTextSessionNameMutation();

  const [messageList, setMessageList] = useState([]);
  const [message, setMessage] = useState("");
  const messageTextAreaRef = useRef(null);
  const messageContainerRef = useRef(null);
  const [textareaOverflow, setTextareaOverflow] = useState(false);
  const [activePosition, setActivePosition] = useState("3.5rem");
  const messageListContainer = useRef();
  const [showPromptManager, setShowPromptManager] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditingId, setIsEditingId] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [chatFiles, setChatFiles] = useState([]);

  const dispatch = useDispatch();
  const profile = useSelector((state) => state.main.profile);
  const sessionOnState = useSelector((state) => state.main.session);
  const maxWidth = useSelector((state) => state.main.sidebarMaxWidth);

  const sideBarIsOpen = maxWidth === maxWidthValue;

  // Drag-and-Drop Handler
  const onDrop = (acceptedFiles) => {
    if (selectedFiles.length >= 2) return; // Prevent adding more if already 2
    let filesToAdd = acceptedFiles;

    if (selectedFiles.length + filesToAdd.length > 2) {
      filesToAdd = filesToAdd.slice(0, 2 - selectedFiles.length);
    }

    setSelectedFiles((prevFiles) => [...prevFiles, ...filesToAdd]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { "application/pdf": [".pdf"] },
  });

  const handleFileSelect = (event) => {
    if (selectedFiles.length >= 2) return; // Prevent adding more if already 2
    const newFiles = Array.from(event.target.files);
    const totalFiles = selectedFiles.length + newFiles.length;

    if (totalFiles > 2) {
      newFiles.length = 2 - selectedFiles.length; // Limit to 2 files total
    }

    setSelectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const handleRemoveFile = (index) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (messageListContainer.current == null) return;
    const scrollHeight = messageListContainer.current.scrollHeight;
    const height = messageListContainer.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    messageListContainer.current.scrollTop =
      maxScrollTop > 0 ? maxScrollTop : 0;
  }, [messageList]);

  useEffect(() => {
    window.addEventListener("beforeunload", async (ev) => {
      if (!sessionOnState) return;
      try {
        fetch(`/close/${sessionOnState}`, {
          method: "get",
          keepalive: true,
        });
      } catch (e) {
        ev.preventDefault();
      }
      return "";
    });

    const profileList = [
      SILBO_PROFILES.SILBO_BRAND_SPECIALIST,
      SILBO_PROFILES.SILBO_SEO_SPECIALIST,
      SILBO_PROFILES.SILBO_AD_COPYWRITER,
      SILBO_PROFILES.SILBO_COMMUNITY,
    ];
    for (const profileLocal of profileList)
      localStorage.removeItem(`session-${profileLocal}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname !== "/general") {
      dispatch(setSession(params.id));
    } else {
      dispatch(setSession(undefined));
    }
  }, [
    location.pathname,
    params.id,
    dispatch,
    getUserResult.data?.user.textSession?.length,
  ]);

  useEffect(() => {
    if (sessionOnState) {
      getTextSessionQuery({ id: sessionOnState, type: "assistant" });
    } else if (!sessionOnState) {
      setMessageList([]);
    }
  }, [sessionOnState, getTextSessionQuery]);

  useEffect(() => {
    if (
      getTextSessionResult.data?.conversation &&
      location.pathname !== "/general"
    ) {
      const messagesOnDb = [];
      getTextSessionResult.data?.conversation?.forEach((item) => {
        messagesOnDb.push({
          message: item.prompt,
          author: AUTHOR.ME,
          fileName: item.fileName || item.fileDetails || "",
        });
        messagesOnDb.push({ message: item.content, author: AUTHOR.BOT });
      });
      setMessageList(messagesOnDb);
    }
  }, [
    getTextSessionResult.data,
    getTextSessionResult.data?.conversation,
    getTextSessionResult.requestId,
    location.pathname,
  ]);

  useEffect(() => {
    if (isSuccess) {
      navigate("/general");
      dispatch(setSession(undefined));
      getUserResult.refetch();
      reset();
    }
  }, [isSuccess, navigate, reset, dispatch, getUserResult]);

  useEffect(() => {
    if (changeTextIsSuccess) {
      setIsEditingId("");
      changeTextReset();
      getUserResult.refetch();
    }
  }, [changeTextIsSuccess, changeTextReset, getUserResult]);

  const onKeyUpMessage = (e) => {
    e.preventDefault();
    if (e.key === "Enter" && e.shiftKey === false) {
      let messageLocal = message;
      if (messageLocal[messageLocal.length - 1] === "\n")
        messageLocal = messageLocal.substring(0, messageLocal.length - 1);

      onSend(messageLocal);
      return;
    }
  };

  const onSend = async (messageLocal) => {
    if (messageLocal.length === 0 && selectedFiles.length === 0) return;
    if (!params.id) setMessageList([]);

    setIsLoading(true);

    let sessionId = "";

    if (!sessionOnState) {
      const { character } = getProfileLiterals(profile);
      const response = await api(`/new?character=${character}`);

      sessionId = response.data.session_id;

      await api.get(`/setmodel/${sessionId}`, {});
    }

    const newMessage = {
      author: AUTHOR.ME,
      message: messageLocal,
      files: selectedFiles.length > 0 ? selectedFiles : null,
    };

    if (selectedFiles.length > 0) {
      const formData = new FormData();
      formData.append("query", messageLocal);

      selectedFiles.forEach((file) => {
        formData.append("file", file);
        formData.append("pdf_name", file.name); // Add the file name to the form data
      });

      await api.post(
        `/text-files/${sessionOnState ? sessionOnState : sessionId}?query=${encodeURIComponent(
          messageLocal,
        )}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      setChatFiles((prevChatFiles) => [...prevChatFiles, selectedFiles]);
    } else {
      await api(
        `/text/${sessionOnState ? sessionOnState : sessionId}?query=${encodeURIComponent(
          messageLocal,
        )}`,
      );

      setChatFiles((prevChatFiles) => [...prevChatFiles, null]);
    }

    setMessageList([...messageList, newMessage]);
    setSelectedFiles([]);
    setMessage("");

    setIsLoading(false);
    getUserResult.refetch();
    getTextSessionQuery({ id: sessionOnState, type: "assistant" });

    if (!sessionOnState) navigate(sessionId);
  };

  const onPromptLibrarySelected = (prompt) => {
    setShowPromptManager(false);
    setMessage(prompt);
  };

  const onSessionNameChange = (session_id, formikValues) => {
    changeTextSessionName({ id: session_id, newName: formikValues.newName });
  };

  const handleTextChange = (newText) => {
    if (isLoading) return;
    setMessage(newText);
  };

  const groupByDate = (data) => {
    if (!data) return;
    const formatted = [...data].reverse().reduce((acc, item) => {
      const date = item.timestamp.split(" ")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    return formatted;
  };

  const { title, description, suggestion, suggestion_plain } =
    getProfileLiterals(profile);

  const groupedData = groupByDate(getUserResult.data?.user?.textSession);

  const logoimage =
    theme().login.case === "Securitas" ? securitasLogo : silboLogo;

  const resizeTextarea = () => {
    if (messageTextAreaRef.current) {
      messageTextAreaRef.current.style.height = "auto";
      messageTextAreaRef.current.style.height = `${messageTextAreaRef.current.scrollHeight}px`;

      const isOverflowing =
        messageTextAreaRef.current.scrollHeight >
        messageTextAreaRef.current.clientHeight;
      setTextareaOverflow(isOverflowing);
    }
  };

  useEffect(() => {
    const updateContainerHeight = () => {
      if (messageContainerRef.current) {
        const containerHeight = messageContainerRef.current.offsetHeight;
        setActivePosition(`${containerHeight + 5}rem`);
      }
    };

    resizeTextarea();
    updateContainerHeight();
  }, [message, selectedFiles]);
  return (
    <>
      {messageList.length === 0 ? (
        <div className="silbo-title-container silbo-title-container-text-assist">
          <div className="text-center">
            <img alt="" height={75} src={logoimage} />
          </div>
          <div
            className="silbo-title"
            style={{ fontSize: "30px", margin: "0.7em 0 0.5rem 0" }}>
            {title}
          </div>
          {theme().login.case === "Silbö" ? (
            <div className="silbo-description">{description}</div>
          ) : null}
        </div>
      ) : null}
      {messageList.length > 0 ? (
        <div
          className="silbo-message-list-container"
          ref={messageListContainer}>
          {messageList.map((item, idx) => {
            const logoAuthor =
              item.author === AUTHOR.BOT
                ? theme().login.case === "Securitas"
                  ? securitasLogo
                  : silboLogo
                : superRealMinLogo;

            const nameAuthor =
              item.author === AUTHOR.BOT
                ? theme().login.case !== "Silbö"
                  ? theme().login.company
                  : "Silbö"
                : t("your_message");
            const markdown = item.message;

            return (
              <div className="pb-3" key={idx}>
                <div className="d-flex align-items-center">
                  <img alt="" src={logoAuthor} style={{ width: "2em" }} />
                  <b style={{ marginLeft: "2em" }}>{nameAuthor}</b>
                </div>
                <div style={{ padding: "0.5em 4em" }}>
                  {item.author === AUTHOR.ME &&
                  (chatFiles[idx] || item.fileName) ? (
                    <div className="uploaded-files mt-1">
                      {/* Render from chatFiles if available */}
                      {!item.fileName
                        ? chatFiles[idx]?.map((file, fileIdx) => (
                            <UploadedFileElement
                              fileName={file.name}
                              key={fileIdx}
                            />
                          ))
                        : null}

                      {/* Render from item.fileName if available */}
                      {item.fileName ? (
                        <UploadedFileElement
                          fileName={item.fileName}
                          key={`item-${idx}`}
                        />
                      ) : null}

                      <p
                        className="mt-2"
                        style={{
                          margin: 0,
                          fontSize: "0.75rem",
                          color: "#BDBDBD",
                          alignItems: "left",
                        }}>
                        Este documento expirará en 3 días.
                      </p>
                    </div>
                  ) : null}

                  <Markdown>{markdown}</Markdown>
                  <div
                    className="mt-3"
                    style={{
                      width: "100%",
                      height: "2px",
                      background: "#C5C5C5",
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      ) : null}

      <div
        className="w-100 text-center"
        style={{ position: "absolute", bottom: "25px" }}>
        {messageList.length === 0 ? (
          <div className="silbo-suggestion-container">
            <div className="suggestion" style={{ padding: "0 0.5em 0 1em" }}>
              <Suggestion
                onClick={() => handleTextChange(suggestion_plain[0])}
                text={suggestion[0]}
                title="Come up with Concepts"
              />
            </div>
            <div className="suggestion" style={{ padding: "0 1em 0 0.5em" }}>
              <Suggestion
                onClick={() => handleTextChange(suggestion_plain[1])}
                text={suggestion[1]}
                title="Come up with Concepts"
              />
            </div>
            <div
              className="suggestion"
              style={{ padding: "1em 0.5em 1em 1em" }}>
              <Suggestion
                onClick={() => handleTextChange(suggestion_plain[2])}
                text={suggestion[2]}
                title="Come up with Concepts"
              />
            </div>
            <div
              className="suggestion"
              style={{ padding: "1em 1em 1em 0.5em" }}>
              <Suggestion
                onClick={() => handleTextChange(suggestion_plain[3])}
                text={suggestion[3]}
                title="Come up with Concepts"
              />
            </div>
          </div>
        ) : null}
        <div
          {...getRootProps({
            className: `silbo-message-input-container ${
              message.length > 0 ? "active" : ""
            }  ${textareaOverflow ? "overflow" : ""}`,
            style: {
              minHeight: "3rem",
              height:
                selectedFiles.length > 0
                  ? "auto"
                  : message.length > 100
                    ? "auto"
                    : "3rem",
              maxHeight: selectedFiles.length > 0 ? "80rem" : undefined,
              display: "flex",
              flexDirection: selectedFiles.length > 0 ? "column" : "row",
              justifyContent: selectedFiles.length > 0 ? "left" : undefined,
              alignItems: selectedFiles.length > 0 ? "flex-start" : undefined,
              padding: isDragActive ? "10px" : undefined,
            },
          })}>
          <input
            {...getInputProps()}
            disabled={selectedFiles.length >= 2}
            id="file-upload"
            multiple
            onChange={handleFileSelect}
            style={{ display: "none" }}
          />
          {selectedFiles.length > 0 ? (
            <div className="selected-files-container">
              {selectedFiles.map((file, index) => (
                <div key={index}>
                  <UploadedFileElement
                    fileName={file.name}
                    onRemove={() => handleRemoveFile(index)}
                  />
                </div>
              ))}
            </div>
          ) : null}
          <div
            style={{
              left: "1em",
              bottom: selectedFiles.length > 0 ? "0.875rem" : "1rem",
              marginRight: selectedFiles.length > 0 ? "1.25rem" : "0.5rem",
              position: selectedFiles.length > 0 ? "absolute" : undefined,
            }}>
            <label
              className="file-upload-label"
              htmlFor="file-upload"
              style={{
                cursor: selectedFiles.length >= 2 ? "not-allowed" : "pointer",
              }}>
              <FileIcon
                className="file-icon"
                style={{
                  fill: message.length > 0 ? "#F0F0F0" : undefined,
                  cursor: selectedFiles.length >= 2 ? "not-allowed" : "pointer",
                  transition: "transform 0.2s ease-in-out",
                }}
              />
            </label>
          </div>

          {isMobile() ? (
            <input
              className="silbo-message-input"
              onChange={(e) => setMessage(e.target.value)}
              onKeyUp={(e) => onKeyUpMessage(e)}
              style={{
                marginLeft: selectedFiles.length > 0 ? "1rem" : undefined,
              }}
              type="text"
              value={isLoading ? "Analizando tu mensaje..." : message}
            />
          ) : (
            <textarea
              className={`silbo-message-input ${isLoading ? "disabled" : ""}`}
              disabled={isLoading}
              onChange={(e) => {
                setMessage(e.target.value);
                resizeTextarea();
              }}
              onClick={(e) => e.stopPropagation()}
              onKeyUp={(e) => onKeyUpMessage(e)}
              placeholder={t("message_positive_placeholder_copywriter")}
              ref={messageTextAreaRef}
              style={{
                width: selectedFiles.length > 0 ? "90%" : undefined,
                height:
                  message.length > 100
                    ? selectedFiles.length > 0
                      ? "3rem"
                      : "auto"
                    : "3rem",
                borderRadius: 0,
                overflow: message.length > 100 ? "auto" : "hidden",
                marginTop: message.length > 100 ? "0.5rem" : "-0.5rem",
                marginRight: "2rem",
                padding: "1rem 0.5rem",
                maxHeight: message.length > 100 ? "10rem" : "3rem",
                marginLeft: selectedFiles.length > 0 ? "2rem" : undefined,
              }}
              type="text"
              value={isLoading ? "Analizando tu mensaje..." : message}
            />
          )}

          {isLoading ? (
            <div
              style={{
                position: "absolute",
                bottom: "-0.2em",
                right: "-2rem",
                height: "5.25rem",
                width: "9.375rem",
                paddingTop: "1rem",
              }}>
              <Lottie animationData={generatingTextImage} loop={true} />
            </div>
          ) : (
            <div
              style={{ position: "absolute", bottom: "-0.2em", right: "1em" }}>
              <SilboSendButton
                active={message.length > 0 || selectedFiles.length > 0}
                activePosition={activePosition}
                onClick={(e) => {
                  onSend(message);
                  e.stopPropagation();
                }}
              />
            </div>
          )}
        </div>
      </div>
      {showPromptManager ? (
        <PromptManager
          onClose={() => setShowPromptManager(false)}
          onSelected={(prompt) => onPromptLibrarySelected(prompt)}
        />
      ) : null}
      <HistorySideBar maxWidth={maxWidth}>
        <div
          className="d-flex justify-content-between"
          style={{
            flexWrap: !sideBarIsOpen ? "wrap" : undefined,
          }}>
          <OverlayTrigger
            overlay={
              <Tooltip>
                <p className="tooltip-text">
                  {sideBarIsOpen ? "Cerrar barra lateral" : "Abrir historial"}
                </p>
              </Tooltip>
            }
            placement={sideBarIsOpen ? "bottom" : "right"}>
            <div
              className="history-container"
              onClick={() =>
                dispatch(
                  setMaxWidth(sideBarIsOpen ? minWidthValue : maxWidthValue),
                )
              }>
              <img alt="history" height={18} src={HistoryImage} width={18} />
              {sideBarIsOpen ? (
                <h5 className="session-title">Historial</h5>
              ) : null}
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            overlay={
              <Tooltip>
                <p className="tooltip-text">Nuevo chat</p>
              </Tooltip>
            }
            placement={sideBarIsOpen ? "bottom" : "right"}>
            <div
              className={`history-container ${sideBarIsOpen ? "mr-3" : ""}`}
              onClick={() => navigate("/general")}>
              <img
                alt="pencil"
                className="image-icon"
                height={18}
                src={pencilIcon}
                width={18}
              />
            </div>
          </OverlayTrigger>
        </div>
        <div className="sessions-container">
          {sideBarIsOpen &&
          !!getUserResult.data &&
          getUserResult.data?.user.textSession?.length > 0
            ? Object.keys(groupedData).map((date) => (
                <div key={date}>
                  <h2 className="group-date">{getSideBarDate(date)}</h2>
                  {groupedData[date].map((session) => (
                    <div
                      className="session-container"
                      key={session.session_id}
                      onClick={() => navigate(`/general/${session.session_id}`)}
                      style={{
                        backgroundColor:
                          session.session_id === params.id
                            ? "#F4F4F4"
                            : undefined,
                      }}>
                      {isEditingId === session.session_id ? (
                        <Formik
                          initialValues={{ newName: "" }}
                          onSubmit={(formikValues) =>
                            onSessionNameChange(
                              session.session_id,
                              formikValues,
                            )
                          }>
                          {(formik) => (
                            <FormikForm>
                              <Form.Control
                                name={formik.newName}
                                onChange={(e) => {
                                  e.preventDefault();
                                  formik.setFieldValue(
                                    "newName",
                                    e.target.value,
                                  );
                                }}
                                placeholder={session.preview}
                                style={{ fontSize: "0.875rem" }}
                                value={formik.values.newName}
                              />
                            </FormikForm>
                          )}
                        </Formik>
                      ) : (
                        <p className="session-text">{session.preview}</p>
                      )}

                      {session.session_id === params.id ? (
                        isDeleting ||
                        changeTextIsChangin ||
                        getUserResult.isLoading ? (
                          <div>
                            <Spinner
                              animation="border"
                              role="status"
                              size="sm"
                              variant="secondary"
                            />
                          </div>
                        ) : (
                          <Dropdown drop="end" key={session.session_id}>
                            <Dropdown.Toggle
                              className="user-info session-container item-container"
                              style={{
                                position: "static",
                                boxShadow: "none",
                                overflow: "hidden",
                              }}
                              variant="secondary">
                              <p className="name">...</p>
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="menu">
                              <Dropdown.Item
                                as="div"
                                onClick={() =>
                                  setIsEditingId(session.session_id)
                                }>
                                <div className="item-container">
                                  <img alt="pencil" src={pencilImage} />
                                  <p>Cambiar nombre</p>
                                </div>
                              </Dropdown.Item>
                              <Dropdown.Item
                                as="div"
                                onClick={() =>
                                  deleteSessionById({
                                    id: session.session_id,
                                    type: "assistant",
                                  })
                                }>
                                <div className="item-container">
                                  <img alt="trash" src={trashImage} />
                                  <p className="delete">Eliminar</p>
                                </div>
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        )
                      ) : (
                        <div />
                      )}
                    </div>
                  ))}
                </div>
              ))
            : null}
        </div>
        <UserInfo
          isStatic={true}
          style={
            sideBarIsOpen
              ? undefined
              : { marginLeft: "-0.60rem", maxWidth: "4rem", padding: ".75rem" }
          }
        />
      </HistorySideBar>
      {isDesktop() ? (
        <div
          className="prompt-library-container"
          onClick={() => setShowPromptManager(true)}
          role="button">
          <div className="silbo-button-container">
            <PromptLibraryImage />
          </div>
          <p className="pt-2">Prompt Library</p>
        </div>
      ) : null}
    </>
  );
};
