import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { getLocalStorage, removeLocalStorage } from "@/utils/localstorage";

import { baseURL } from "@/service/instance";

const baseQuery = fetchBaseQuery({
  baseUrl: baseURL,
  prepareHeaders: (headers) => {
    const token = getLocalStorage("token");
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401 || result?.error?.status === 422) {
    removeLocalStorage("token");
    window.location.href = "/login";
  }
  return result;
};

export const UserApi = createApi({
  reducerPath: "userApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (values) => ({ url: "/login", method: "POST", body: values }),
    }),
    updatePassword: builder.mutation({
      query: (values) => ({
        url: "/update-password",
        method: "POST",
        body: values,
      }),
    }),
    getUser: builder.query({
      query: () => "/user",
    }),
    getSession: builder.query({
      query: ({ id, type }) => `/get-session/${id}/${type}`,
    }),
    deleteSession: builder.mutation({
      query: ({ id, type }) => ({
        url: `/delete-session/${id}/${type}`,
        method: "DELETE",
      }),
    }),
    changeTextSessionName: builder.mutation({
      query: ({ id, newName }) => ({
        url: `/text/${id}?new-name=${newName}`,
        method: "PATCH",
      }),
    }),
    changeImageSessionName: builder.mutation({
      query: ({ id, newName }) => ({
        url: `/image/${id}?new-name=${newName}`,
        method: "PATCH",
      }),
    }),
    getSasToken: builder.query({
      query: () => "sas-token",
    }),
    restorePassowrd: builder.mutation({
      query: (values) => ({
        url: "restore-password",
        method: "POST",
        body: values,
      }),
    }),
    sendFeedback: builder.mutation({
      query: (values) => ({
        url: "send-feedback",
        method: "POST",
        body: values,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useLazyGetUserQuery,
  useGetUserQuery,
  useLazyGetSessionQuery,
  useUpdatePasswordMutation,
  useDeleteSessionMutation,
  useChangeTextSessionNameMutation,
  useChangeImageSessionNameMutation,
  useGetSasTokenQuery,
  useRestorePassowrdMutation,
  useSendFeedbackMutation,
} = UserApi;
