import React from "react";

import Product from "./Product";
import "./ProductAssistant.css";
import ProductBodegon from "./ProductBodegon";

export const ProductAssistant = ({
  products,
  selectedProduct,
  setSelectedProduct,
  type,
}) => {
  const containerClass = type
    ? "two-per-row"
    : products.length > 5
      ? "three-per-row"
      : "";

  return (
    <>
      <h3 className="product-card-title">
        {type ? "Selecciona Bodegón" : "Selecciona Producto"}
      </h3>
      <div className={`product-card-container ${containerClass}`}>
        {products.map((product) =>
          type ? (
            <ProductBodegon
              key={product.id}
              product={product}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
            />
          ) : (
            <Product
              key={product.id}
              product={product}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
            />
          ),
        )}
      </div>
    </>
  );
};

export default ProductAssistant;
