import React from "react";

import { ReactComponent as FileUploaded } from "@/assets/img/UploadedFile.svg";

export const removePdfExtension = (fileName) => {
  if (fileName.toLowerCase().endsWith(".pdf")) {
    return fileName.slice(0, -4); // Remove the last 4 characters
  }
  return fileName;
};
export const shortenFileName = (fileName, maxLength = 25) => {
  if (fileName.length <= maxLength) return fileName;

  const extension = fileName.slice(fileName.lastIndexOf("."));
  const nameWithoutExtension = removePdfExtension(fileName);
  const charsToShow = maxLength - extension.length - 3; // 3 for the ellipsis

  if (charsToShow <= 0) return `...${extension}`;

  const startChars = Math.ceil(charsToShow / 2);
  const endChars = Math.floor(charsToShow / 2);

  return `${nameWithoutExtension.slice(0, startChars)}...${nameWithoutExtension.slice(-endChars)}${extension}`;
};
export const UploadedFileElement = ({ fileName, onRemove }) => (
  <div
    style={{
      width: "17rem",
      height: "4rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0.75rem",
      border: "1px solid #FFFFFF",
      borderRadius: "12px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#fff",
      position: "relative",
    }}>
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}>
      <div
        style={{
          width: "45px",
          height: "50px",
          backgroundColor: "var(--main-color)",
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "0.5rem",
        }}>
        <FileUploaded style={{ width: "24px", height: "24px" }} />
      </div>
      <div
        style={{
          textAlign: "left",
          marginRight: "1rem",
        }}>
        <p
          style={{
            margin: 0,
            fontSize: "0.875rem",
            fontWeight: "500",
            color: "#6A6A6A",
          }}>
          {shortenFileName(fileName)}
        </p>
        <p
          style={{
            margin: 0,
            fontSize: "0.75rem",
            color: "#BDBDBD",
            alignItems: "left",
          }}>
          PDF
        </p>
      </div>
    </div>
    {onRemove ? (
      <div
        onClick={(e) => {
          onRemove();
          e.stopPropagation();
        }}
        role="button"
        style={{
          width: "1.5rem",
          height: "1.5rem",
          position: "absolute",
          top: "-7px",
          right: "-7px",
          background: "#fff",
          boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
          borderRadius: "50%",
          cursor: "pointer",
          fontSize: "1.25rem",
          color: "#666",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        &times;
      </div>
    ) : null}
  </div>
);

export default UploadedFileElement;
