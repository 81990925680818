import { Button, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useEffect, useState } from "react";

import ConversationImage from "@/assets/img/conversations.svg";
import { ReactComponent as FeedbackImage } from "@/assets/img/feedback.svg";

import theme from "@/branding/theme";
import { useSendFeedbackMutation } from "@/service/user";

import "./Feedback.css";

const FeedBack = () => {
  const { t } = useTranslation();
  const [feedBackModalIsOpen, setFeedBackModalIsOpen] = useState(false);
  const [feedbackSentModalIsOpen, setFeedbackSentModalIsOpen] = useState(false);
  const [feedbackText, setFeedbackText] = useState("");

  const [sendFeedback, { isSuccess, reset, isLoading }] =
    useSendFeedbackMutation();

  const handleSubmit = () => {
    sendFeedback({ message: feedbackText, navigator: navigator.userAgent });
  };

  useEffect(() => {
    if (isSuccess) {
      setFeedBackModalIsOpen(false);
      setFeedbackSentModalIsOpen(true);
      reset();
    }
  }, [isSuccess, reset]);

  return (
    <>
      <div
        className="feedback-container"
        onClick={() => setFeedBackModalIsOpen(true)}>
        <div className="feedback-icon-container">
          <FeedbackImage />
        </div>
        <p>{t("home-feedback")}</p>
      </div>

      <Modal
        aria-labelledby="send feedback modal"
        centered
        className="feedback-modal"
        onHide={() => setFeedBackModalIsOpen(false)}
        show={feedBackModalIsOpen}
        size="lg">
        <Modal.Header bsPrefix="custom-modal-header" closeButton>
          <div />
        </Modal.Header>
        <Modal.Title className="feedback-title-text">
          <h4>¡Compártenos tus comentarios o recomendaciones!</h4>
        </Modal.Title>
        <p>
          {`${theme().login.loginTitle} es una herramienta en construcción y tu feedback es muy útil
          para nosotros.`}
        </p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}>
          <textarea
            className="feedback-input"
            onChange={(e) => setFeedbackText(e.target.value)}
            rows={7}
            type="text"
            value={feedbackText}
          />
          <Button
            className="feedback-button"
            disabled={feedbackText === ""}
            type="submit">
            Enviar {isLoading ? <Spinner size="sm" /> : null}
          </Button>
        </form>
      </Modal>

      <Modal
        centered
        onHide={() => setFeedbackSentModalIsOpen(false)}
        show={feedbackSentModalIsOpen}
        size="md">
        <Modal.Header bsPrefix="custom-modal-header" closeButton>
          <div />
        </Modal.Header>
        <Modal.Body>
          <div className="success-modal">
            <img alt="conversation image" src={ConversationImage} />
            <h4>¡Muchas gracias por tu aportación!</h4>
            <p>
              {`La revisaremos detenidamente y nos pondremos en contacto contigo.
              De nuevo, ¡gracias por usar ${theme().login.loginTitle}!`}
            </p>
            <Button
              className="feedback-button feedback-sent"
              onClick={() => {
                setFeedbackSentModalIsOpen(false);
              }}>
              Continuar
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default FeedBack;
