import dependienteFarmacia from "@/assets/img/promptsDefaultImages/character_companies_dependiente_farmacia.png";
import dependienteSupermecado from "@/assets/img/promptsDefaultImages/character_companies_dependiente_supermecado.png";
import empresarioEjecutivo from "@/assets/img/promptsDefaultImages/character_companies_empresario_ejecutivo.png";
import equipoNegocios from "@/assets/img/promptsDefaultImages/character_companies_equipo_de_negocios.png";
import equipoSoporteTecnico from "@/assets/img/promptsDefaultImages/character_companies_equipo_soporte_tecnico.png";
import jovenesEmprendedores from "@/assets/img/promptsDefaultImages/character_companies_jovenes_emprendedores.png";
import profTrabajandoCasa from "@/assets/img/promptsDefaultImages/character_companies_prof_trabajando_desde_casa.png";
import vendedorPanaderia from "@/assets/img/promptsDefaultImages/character_companies_vendedor_panaderia.png";
import vendedorTiendaFlores from "@/assets/img/promptsDefaultImages/character_companies_vendedor_tienda_de_flores.png";
import vendedorTiendaTecnologia from "@/assets/img/promptsDefaultImages/character_companies_vendedor_tienda_tecnologia.png";
import silbyBiblioteca from "@/assets/img/promptsDefaultImages/character_silby_biblioteca.png";
import silbyHawai from "@/assets/img/promptsDefaultImages/character_silby_hawai.png";
import silbyNieve from "@/assets/img/promptsDefaultImages/character_silby_nieve.png";
import silbyOficina from "@/assets/img/promptsDefaultImages/character_silby_oficina.png";
import silbyParque from "@/assets/img/promptsDefaultImages/character_silby_parque.png";
import silbyPlaya from "@/assets/img/promptsDefaultImages/character_silby_playa.png";
import silbyTribal from "@/assets/img/promptsDefaultImages/character_silby_tribal.png";
import silbyUniversidad from "@/assets/img/promptsDefaultImages/character_silby_universidad.png";
import silbyVr from "@/assets/img/promptsDefaultImages/character_silby_vr.png";
import andolecenteAndandoCiudad from "@/assets/img/promptsDefaultImages/character_users_andolecente_andando_ciudad.png";
import chicaEntrenando from "@/assets/img/promptsDefaultImages/character_users_chica_entrenando.png";
import chicoCafe from "@/assets/img/promptsDefaultImages/character_users_chico_cafe.png";
import familiaParque from "@/assets/img/promptsDefaultImages/character_users_familia_parque.png";
import familiaViendoTele from "@/assets/img/promptsDefaultImages/character_users_familia_viendo_tele.png";
import hombrePaseoPerro from "@/assets/img/promptsDefaultImages/character_users_hombre_paseo_perro.png";
import jovenEstudiandoBiblioteca from "@/assets/img/promptsDefaultImages/character_users_joven_estudiando_biblioteca.png";
import hijoVideojuegos from "@/assets/img/promptsDefaultImages/character_users_padre_hijo_videojuegos.png";
import parejaParque from "@/assets/img/promptsDefaultImages/character_users_pareja_parque.png";
import skaterMovil from "@/assets/img/promptsDefaultImages/character_users_skater_con_movil.png";

const response = [
  {
    character: "Images",
    prompt_list: [
      {
        key: "Silby en la oficina",
        template:
          "Silby with sunglasses, the cheeky bird with a light green chest and an orange-toned face. The bird has vibrant orange, green, and blue feathers with detailed and textured feathers and expressive eyes. Silby is standing on an office desk.",
        image: silbyOficina,
      },
      {
        key: "Silby en un parque",
        template:
          "Silby with sunglasses, the cheeky bird with a light green chest and an orange-toned face. The bird has vibrant orange, green, and blue feathers with detailed and textured feathers and expressive eyes. Silby is placed prominently in a park with a blurred swing in the background.",
        image: silbyParque,
      },
      {
        key: "Silby en una biblioteca",
        template:
          "Silby with sunglasses, the cheeky bird with a light green chest and an orange-toned face. The bird has vibrant orange, green, and blue feathers with detailed and textured feathers and expressive eyes. Silby is in a library with books and shelves alone.",
        image: silbyBiblioteca,
      },
      {
        key: "Silby en Waikiki (Hawai)",
        template:
          "A cheeky and self-confident bird named Silby with vibrant orange, green, and blue feathers, detailed and textured feathers, and expressive eyes. Silby is dressed in a Hawaiian style, ((wearing a colorful lei made of tropical flowers around its neck)). The background includes a beautiful Hawaiian beach with palm trees, clear blue water, and a stunning sunset. Silby is posed proudly with its chest out and head held high, showcasing its playful and confident personality while embracing the Hawaiian culture and scenery.",
        image: silbyHawai,
      },
      {
        key: "Silby graduándose de la universidad",
        template:
          "A cheeky and self-confident bird named Silby with vibrant orange, green, and blue feathers, detailed and textured feathers, and expressive eyes. Silby is wearing a graduation cap, symbolizing academic achievement. The background includes a university campus with buildings and greenery, emphasizing the academic setting. Silby is posed proudly with its chest out and head held high, showcasing its playful and confident personality while celebrating graduation.",
        image: silbyUniversidad,
      },
      {
        key: "Silby tribal",
        template:
          "A cheeky and self-confident bird named Silby with vibrant orange, green, and blue feathers, detailed and textured feathers, and expressive eyes. Silby is dressed in rasta style, with a colorful knitted hat, dreadlocks, and vibrant accessories. The background includes a laid-back, tropical setting with palm trees and a relaxed atmosphere. Silby is posed proudly with its chest out and head held high, showcasing its playful and confident personality while embracing the rasta lifestyle.",
        image: silbyTribal,
      },
      {
        key: "Silby en la nieve",
        template:
          "A cheeky and self-confident bird named Silby with vibrant orange, green, and blue feathers, detailed and textured feathers, and expressive eyes. Silby is wearing a ((cozy winter hat with a pom-pom on top)), perfect for snowy weather. The background includes a picturesque snowy landscape with snow-covered trees, mountains, and gently falling snowflakes. Silby is posed proudly with its chest out and head held high, showcasing its playful and confident personality while enjoying the winter wonderland.",
        image: silbyNieve,
      },
      {
        key: "Silby con gafas VR",
        template:
          "A cheeky and self-confident bird named Silby with vibrant orange, green, and blue feathers, detailed and textured feathers, and expressive eyes. Silby is wearing a ((cozy winter hat with a pom-pom on top)), perfect for snowy weather. The background includes a picturesque snowy landscape with snow-covered trees, mountains, and gently falling snowflakes. Silby is posed proudly with its chest out and head held high, showcasing its playful and confident personality while enjoying the winter wonderland.",
        image: silbyVr,
      },
      {
        key: "Silby disfrutando de la playa",
        template:
          "A cheeky and self-confident bird named Silby with vibrant orange, green, and blue feathers, detailed and textured feathers, and expressive eyes. Silby is wearing a ((cozy winter hat with a pom-pom on top)), perfect for snowy weather. The background includes a picturesque snowy landscape with snow-covered trees, mountains, and gently falling snowflakes. Silby is posed proudly with its chest out and head held high, showcasing its playful and confident personality while enjoying the winter wonderland.",
        image: silbyPlaya,
      },
    ],
  },
  {
    character: "Companies",
    prompt_list: [
      {
        key: "Vendedor – Tienda de tecnología.",
        template:
          "salesman in his technology store. The salesman, a man in his 30s with short, neat hair, is wearing a green shirt and khaki pants. He has a warm smile and confident eyes. He is holding a tablet in one hand and pointing to a display of the latest smartphones with the other. The store is filled with various tech gadgets like laptops, headphones, and smartwatches. The natural lighting highlights the warm colors, creating a welcoming atmosphere. The background is minimalistic with shelves of tech products, focusing on the salesman and his successful business",
        image: vendedorTiendaTecnologia,
      },
      {
        key: "Dependiente en Farmacia",
        template:
          "shopkeeper in her modern and minimal pharmacy. The shopkeeper, a woman in her 40s with curly brown hair, is wearing a stylish green medical gown. Her face shows a warm smile, and her expressive eyes reflect joy and satisfaction. She is standing behind the pharmacy counter, holding an iPad in her hands, demonstrating her modernity and technological savvy in managing the business. Around her are minimal shelves filled with some medications, health products, and wellness items",
        image: dependienteFarmacia,
      },
      {
        key: "Equipo de Soporte técnico",
        template:
          "technical support agent with headphones in action. A woman in their 30s and wearing business-casual attire with green and orange shirts. She has warm smile and confident eyes as she assist customers. She holds a tablet or works on a computer, showcasing her technological expertise. She is in a modern office with ergonomic desks, multiple monitors, and tech gadgets. Clear natural lighting highlights the professional atmosphere. The background is minimalistic with subtle branding and organized workstations, focusing on the team's collaboration and success",
        image: equipoSoporteTecnico,
      },
      {
        key: "Empresario ejecutivo en videollamada",
        template:
          "smiling executive businessman in a video conference. The executive, a man in his 40s with short, neat hair, is wearing a sharp suit and tie. He is sitting at a modern desk, looking at a laptop screen with a focused and engaged expression. The natural lighting highlights his professional demeanor and the sleek, organized workspace. The background is minimalistic with simple decor, emphasizing the executive's participation in the video conference",
        image: empresarioEjecutivo,
      },
      {
        key: "Jóvenes emprendedores en coworking",
        template:
          "1 white girl in a coworking space with people in the background. The group consists of diverse men and women in their 20s and 30s, wearing casual business attire. They are working on laptops, having discussions, and collaborating at shared desks. The space is modern with natural lighting, comfortable seating, and greenery, creating a vibrant and productive atmosphere. The background is minimalistic with stylish decor, focusing on the dynamic and collaborative environment of the coworking space",
        image: jovenesEmprendedores,
      },
      {
        key: "Dependiente de supermercado",
        template:
          "local organic market worker in their 30s wearing a green aprons and smile warmly. Holding a tablet, showing his tech-savvy in managing tasks. The market is organic, made of wood and green with different sections with shelves of fruits, vegetables, canned goods, and dairy products",
        image: dependienteSupermecado,
      },
      {
        key: "Equipo de negocios en una reunión",
        template:
          "a corporate team in a modern and organic meeting room with plants. A group of men and women in their 30s and 40s wearing business attire. They are sitting around a table, talking and smiling. The modern office has natural lighting, creating a professional and collaborative atmosphere. The background is simple, focusing on the team's interaction and teamwork",
        image: equipoNegocios,
      },
      {
        key: "Profesional trabajando desde casa",
        template:
          "a professional working remotely. The individual, in their 30s, is sitting at a desk with a laptop, wearing casual business attire. They have a focused and engaged expression. The setting is a cozy home office with natural lighting, creating a productive and comfortable atmosphere. The background is simple and organized, emphasizing the professional's remote work environment",
        image: profTrabajandoCasa,
      },
      {
        key: "Vendedor – Tienda de Flores",
        template:
          "owner of a flower store smiling while using a tablet. The owner is a woman in her 40s with long brown hair, is wearing a stylish green apron over her clothes. Her face shows a warm smile, and her expressive eyes reflect joy and satisfaction.The background is simple and minimalistic, ensuring the focus remains on the shopkeeper. The overall composition exudes professionalism and success, highlighting the joy and satisfaction of the shopkeeper in her thriving business",
        image: vendedorTiendaFlores,
      },
      {
        key: "Vendedor – Panadería",
        template:
          "owner of a bakery store smiling while using a tablet. The owner is a old man in his 60s with grey hair, is wearing a stylish green apron over her clothes. His face shows a warm smile, and his expressive eyes reflect joy and satisfaction.The background is simple and minimalistic, ensuring the focus remains on the shopkeeper. The overall composition exudes professionalism and success, highlighting the joy and satisfaction of the shopkeeper in his thriving business",
        image: vendedorPanaderia,
      },
    ],
  },
  {
    character: "Users",
    prompt_list: [
      {
        key: "Adolescente andando por la ciudad",
        template:
          "smiling girl walking by the city with a smartphone. The city is lush with greenery and colorful. Natural lighting highlights the joyful and romantic atmosphere",
        image: andolecenteAndandoCiudad,
      },
      {
        key: "Pareja de paseo por el parque",
        template:
          "couple walking in the park. The couple in their 30s, are holding hands and smiling at each other while looking at a smartphone. The park is lush with greenery and colorful flowers. Natural lighting highlights the joyful and romantic atmosphere. The background is simple, focusing on the couple's connection and happiness",
        image: parejaParque,
      },
      {
        key: "Chico en un café moderno",
        template:
          "a man on his 20s and 30s enjoying coffee at an outdoor organic and vegan coffee shop holding a smartphone. The café has a charming street view with natural lighting creating a warm and inviting atmosphere. The background includes other patrons and the urban setting",
        image: chicoCafe,
      },
      {
        key: "Familia en un parque",
        template:
          "a young family; father playing in the park. The family, including parents and and one teenager, are having a picnic on a blanket. The father is holding a smartphone, taking pictures of the children playing. The park is green and vibrant with trees and playground equipment. Natural lighting enhances the lively and joyful atmosphere",
        image: familiaParque,
      },
      {
        key: "Hombre de paseo con su perro",
        template:
          "a man walking his 1 dog in the city. The man, in his 40s, is casually dressed and smiling. He is holding a smartphone and the dog leash, listening to music or a podcast. The city street is lined with trees and shops. Natural lighting enhances the friendly and relaxed atmosphere. The background includes other pedestrians and urban elements",
        image: hombrePaseoPerro,
      },
      {
        key: "Adolescente skater con el móvil",
        template:
          "a teenager skateboarding in a park holding a smartphone, recording a video. The park features a skate ramp and vibrant graffiti art. Natural lighting highlights the energetic and fun atmosphere. The background includes blurred trees and other park-goers, enhancing the focus on the teens and their dynamic activity. Ensure all faces are clearly visible, well-proportioned, and expressive, showing joyful and natural expressions. The overall composition exudes excitement and youthful energy, with a crisp foreground and a softly blurred background",
        image: skaterMovil,
      },
      {
        key: "Padre e hijo jugando a videojuegos",
        template:
          "a father and son playing video games looking at the TV together. The father, in his 40s, and the son, around 10 years old, are sitting on a comfortable sofa in a modern living room. Both are holding game controllers. The room is lit with natural lighting, creating a warm and inviting atmosphere. The background includes a subtle decor elements like a bookshelf and plants. Ensure all faces are clearly visible, well-proportioned, and expressive, showing joyful and natural expressions. The overall composition exudes a sense of fun, bonding, and family enjoyment",
        image: hijoVideojuegos,
      },
      {
        key: "Chica entrenando en el gimnasio",
        template:
          "a woman using her mobile phone in a organic gym. The woman, in her late 20s, is wearing stylish workout attire and is seated on a bench, taking a break from her exercise routine. She is holding her smartphone, looking at the screen with a focused and relaxed expression. The gym environment includes various workout equipment like dumbbells and treadmills. The background is white and simple",
        image: chicaEntrenando,
      },
      {
        key: "Familia viendo la tele en el comedor",
        template:
          "a family watching television at home, viewed from behind. The family, consisting of parents and two children, is sitting comfortably on a large sofa in a cozy living room. The parents are in their late 30s, and the children are around 8 and 10 years old. They are all facing the TV screen, which is showing a wildlife documentary slightly out of focus. The soft glow of the screen illuminates their faces. The room is warmly lit with natural lighting and has a welcoming atmosphere, featuring decor elements like a coffee table, bookshelves, and plants. The background shows the TV screen with blurred images of nature and animals. Ensure the family members' postures and interactions appear natural and relaxed, conveying a sense of togetherness and enjoyment.",
        image: familiaViendoTele,
      },
      {
        key: "Joven estudiando en la biblioteca",
        template:
          "a student using a laptop in a modern library. The student, a young woman in her early 20s, is seated at a wooden table surrounded by bookshelves filled with books. She is focused on her laptop screen, with a thoughtful and engaged expression. She is wearing casual, comfortable clothing and has her hair neatly tied back. The library environment is quiet and studious, white and modern, with natural lighting streaming in through large windows, creating a warm and inviting atmosphere. The background includes blurred shelves of books and other students studying, ensuring the focus remains on the student and her activity. Ensure the student's face is clearly visible, well-proportioned, and expressive, showing concentration and engagement",
        image: jovenEstudiandoBiblioteca,
      },
    ],
  },
];

export default response;
