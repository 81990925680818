const HistoryItem = (props) => (
  <div
    className={`${props.className} pe-1`}
    onClick={() => props.onClick()}
    role="button">
    <img alt="" className="border w-100" src={props.img_1} />
    <img alt="" className="border w-100" src={props.img_2} />
    <div>v1</div>
  </div>
);
export default HistoryItem;
