import { useState } from "react";

const SilboAdCreatorStep4CopiesItem = (props) => {
  const [active, setActive] = useState(false);
  return (
    <div
      className={`w-100 p-4 ${props.className} silbo-ad-creator-copies-item ${active ? "active" : ""}`}
      onClick={() => props.onClick()}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      role="button">
      <label
        className={`fw-bold insight  ${active ? "active" : ""}`}
        role="button">
        Insight#{props.idx + 1}
      </label>
      <div className="mt-3 silbo-ad-creator-insight-text">{props.data}</div>
    </div>
  );
};

export default SilboAdCreatorStep4CopiesItem;
