import { useState } from "react";

import downloadImage from "@/assets/img/download.svg";
import downloadImageHover from "@/assets/img/download_hover.svg";

const DownloadButton = (props) => {
  const [hover, setHover] = useState(false);

  const base64ToBlob = (base64, mime) => {
    const base64WithoutPrefix = base64.split(";base64,").pop();

    const byteCharacters = atob(base64WithoutPrefix);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mime });
  };

  const downloadBase64File = (base64Data, fileName) => {
    // const linkSource = `data:image/jpg;base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    const blob = base64ToBlob(base64Data, "image/jpeg");
    const url = URL.createObjectURL(blob);

    downloadLink.href = url;
    downloadLink.download = fileName;

    return downloadLink;
  };

  return (
    <div
      className="download"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <a
        download={
          props.value.startsWith("data:image")
            ? downloadBase64File(props.value, "upscaledImage.jpg").download
            : props.value
        }
        href={
          props.value.startsWith("data:image")
            ? downloadBase64File(props.value, "upscaledImage.jpg").href
            : props.value
        }
        style={{ color: "inherit", textDecoration: "none" }}>
        <img alt="" src={hover ? downloadImageHover : downloadImage} />
      </a>
    </div>
  );
};
export default DownloadButton;
