import { Form, Formik } from "formik";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useEffect, useState } from "react";

import emailIcon from "@/assets/img/email-icon.svg";
import passwordIcon from "@/assets/img/password-icon.svg";
import visibilitIcon from "@/assets/img/visibility.svg";

import RememberPasswordModal from "@/components/RememberPassword/RememberPasswordModal";

import { setLocalStorage } from "@/utils/localstorage";

import theme from "@/branding/theme";
import { useLazyGetUserQuery, useLoginMutation } from "@/service/user";

import "./LoginForm.css";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string().required("Required"),
});

const LoginForm = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showRememberModal, setShowRememberModal] = useState(false);
  const [login, { isSuccess, data, isLoading, error }] = useLoginMutation();
  const [getUserQuery] = useLazyGetUserQuery({});

  useEffect(() => {
    if (isSuccess) {
      setLocalStorage("token", data.token);
      getUserQuery();
      navigate("/");
    }
  }, [isSuccess, data, getUserQuery, navigate]);

  return (
    <>
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={(values) => {
          login(values);
        }}
        validateOnChange={false}
        validateOnMount={false}
        validationSchema={validationSchema}>
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form className="login-form" onSubmit={handleSubmit}>
            <div className="input-container">
              <div className="label-container">
                <img alt="email logo" src={emailIcon} />
                <p
                  className="label-login"
                  style={{
                    color: errors.email && touched.email ? "red" : undefined,
                  }}>
                  Email
                </p>
              </div>
              <input
                className="input-login"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder="Introduce tu email"
                style={{
                  borderColor:
                    errors.email && touched.email ? "red" : undefined,
                }}
                type="email"
                value={values.email}
              />
            </div>
            <div className="input-container">
              <div className="label-container">
                <img alt="password logo" src={passwordIcon} />
                <p
                  className="label-login"
                  style={{
                    color:
                      errors.password && touched.password ? "red" : undefined,
                  }}>
                  Password
                </p>
              </div>
              <div className="password-container">
                <input
                  className="input-login"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder="*******"
                  style={{
                    borderColor:
                      errors.password && touched.password ? "red" : undefined,
                  }}
                  type={showPassword ? "text" : "password"}
                  value={values.password}
                />

                <img
                  alt="eye"
                  className="eye"
                  onMouseEnter={() => setShowPassword(true)}
                  onMouseLeave={() => setShowPassword(false)}
                  src={visibilitIcon}
                />
              </div>
            </div>
            <div>
              <div className="remember-password-container">
                <p
                  className="label-mahou-buttons"
                  onClick={() => {
                    setShowRememberModal(true);
                  }}>
                  Recordar contraseña
                </p>
              </div>
              {error?.data?.error ? (
                <p className="error-message">
                  El correo o la contraseña introducida no son correctos.
                </p>
              ) : null}
            </div>
            <Button
              disabled={isLoading}
              onMouseEnter={(e) => {
                e.target.style.background =
                  theme().login.case === "Securitas" ? "#000000" : "#FF0033";
                e.target.style.color =
                  theme().login.case === "Securitas" ? "#FF0033" : "#000000";
              }}
              onMouseLeave={(e) => {
                e.target.style.background =
                  theme().login.case === "Securitas" ? "#FF0033" : "#000000";
                e.target.style.color =
                  theme().login.case === "Securitas" ? "#FFFFFF" : "#FFFFFF";
              }}
              style={{
                minHeight: "2.75rem",
                fontSize: "0.85rem",
                border: "none",
                background:
                  theme().login.case === "Securitas" ? "#FF0033" : "#000000",
                color:
                  theme().login.case === "Securitas" ? "#FFFFFF" : "#FFFFFF",
                transition: "background 0.3s, color 0.3s",
              }}
              type="submit">
              Log in
            </Button>
          </Form>
        )}
      </Formik>

      <RememberPasswordModal
        handleClose={() => setShowRememberModal(false)}
        show={showRememberModal}
      />
    </>
  );
};
export default LoginForm;
