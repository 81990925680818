import { Form, Formik } from "formik";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { useEffect, useState } from "react";

import passwordIcon from "@/assets/img/password-icon.svg";
import updatedPasswordImage from "@/assets/img/updated-password.svg";
import visibilitIcon from "@/assets/img/visibility.svg";

import RememberPasswordModal from "@/components/RememberPassword/RememberPasswordModal";

import theme from "@/branding/theme";
import { useUpdatePasswordMutation } from "@/service/user";

import "./UpdatePasswordModal.css";

const validationSchema = Yup.object().shape({
  password: Yup.string().required("Contraseña actual es requerida"),
  newPassword: Yup.string().required("Nueva contraseña es requerida"),
});

const UpdatePasswordModal = (props) => {
  const { t } = useTranslation();
  const [succesModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showRememberModal, setShowRememberModal] = useState(false);

  const [updatePassword, { isSuccess, isLoading, error, reset }] =
    useUpdatePasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      props.handleClose();
      setSuccessModalIsOpen(true);
      reset();
    }
  }, [isSuccess, props, reset]);

  return (
    <>
      <Modal
        aria-labelledby="update password modal"
        centered
        onHide={props.handleClose}
        show={props.show}
        size="md">
        <Modal.Header bsPrefix="custom-modal-header" closeButton>
          <Modal.Title className="title-text">
            {t("update_password_title")}
          </Modal.Title>
        </Modal.Header>
        <p className="subtitle-text">{t("update_password_subtitle")}</p>
        <Modal.Body>
          <Formik
            initialValues={{ password: "", newPassword: "" }}
            onSubmit={(values) => {
              updatePassword({
                password: values.password,
                newPassword: values.newPassword,
              });
            }}
            validateOnChange={false}
            validateOnMount={false}
            validationSchema={validationSchema}>
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <Form className="update-password-form" onSubmit={handleSubmit}>
                <div className="input-container">
                  <div className="label-container">
                    <img alt="password logo" src={passwordIcon} />
                    <p
                      className={`${theme().login.case === "Mahou" ? "label-mahou" : "label"}`}
                      style={{
                        color:
                          (errors.password && touched.password) ||
                          error?.data?.error
                            ? "red"
                            : undefined,
                      }}>
                      Contraseña actual
                    </p>
                  </div>
                  <div className="password-container">
                    <input
                      className="password-input"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="*******"
                      style={{
                        borderColor:
                          (errors.password && touched.password) ||
                          error?.data?.error
                            ? "red"
                            : undefined,
                      }}
                      type={showPassword ? "text" : "password"}
                      value={values.password}
                    />

                    <img
                      alt="eye"
                      className="eye"
                      onMouseEnter={() => setShowPassword(true)}
                      onMouseLeave={() => setShowPassword(false)}
                      src={visibilitIcon}
                    />
                  </div>
                </div>
                <div className="input-container">
                  <div className="label-container">
                    <img alt="password logo" src={passwordIcon} />
                    <p
                      className={`${theme().login.case === "Mahou" ? "label-mahou" : "label"}`}
                      style={{
                        color:
                          errors.newPassword && touched.newPassword
                            ? "red"
                            : undefined,
                      }}>
                      Nueva contraseña
                    </p>
                  </div>
                  <div className="password-container">
                    <input
                      className="password-input"
                      name="newPassword"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      placeholder="*******"
                      style={{
                        borderColor:
                          errors.newPassword && touched.newPassword
                            ? "red"
                            : undefined,
                      }}
                      type={showConfirmPassword ? "text" : "password"}
                      value={values.newPassword}
                    />

                    <img
                      alt="eye"
                      className="eye"
                      onMouseEnter={() => setShowConfirmPassword(true)}
                      onMouseLeave={() => setShowConfirmPassword(false)}
                      src={visibilitIcon}
                    />
                  </div>
                </div>
                <div>
                  <div className="remember-password-container">
                    <p
                      className="remember-password"
                      onClick={() => {
                        setShowRememberModal(true);
                        props.handleClose();
                      }}>
                      Recordar contraseña
                    </p>
                  </div>
                  {error?.data?.error ? (
                    <p className="error-message">Contraseña incorrecta</p>
                  ) : null}
                </div>
                <Button
                  className="confirm-button"
                  disabled={isLoading}
                  type="submit">
                  Recuperar
                </Button>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        onHide={() => setSuccessModalIsOpen(false)}
        show={succesModalIsOpen}
        size="md">
        <Modal.Header bsPrefix="custom-modal-header" closeButton>
          <div />
        </Modal.Header>
        <Modal.Body>
          <div className="success-modal">
            <img alt="success" src={updatedPasswordImage} />
            <h4>Tu contraseña ha sido actualizada con éxito</h4>
            <p>
              Podrás acceder con ella la próxima vez que entres en la plataforma
              con esta nueva contraseña
            </p>
          </div>
        </Modal.Body>
      </Modal>

      <RememberPasswordModal
        handleClose={() => setShowRememberModal(false)}
        show={showRememberModal}
      />
    </>
  );
};
export default UpdatePasswordModal;
