import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import sampleLandscape from "@/assets/img/ad_creator/sample_landscape.svg";
// Images
// NOTE: INIT This makes slower
import samplePortrait from "@/assets/img/ad_creator/sample_portrait.svg";

import SilboAdCreatorFooter from "@/components/Ad_creator/Footer";
import { SilboAdCreatorStepBar } from "@/components/Ad_creator/StepBar";

// NOTE: END This makes slower
import { setAdCreatorStepNext, setData } from "@/redux/store";
import { LOADING_V2_MODE, setLoadingV2, setLoadingV2Mode } from "@/redux/store";

import { SILBO_MODELS } from "@/utils";

export const SilboAdCreatorStep6Creativities = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.adCreator.data);
  const session = useSelector((state) => state.adCreator.session);
  const fakeMode = useSelector((state) => state.main.fakeMode);

  const onClick = async (e) => {
    e.stopPropagation();
    try {
      // Launch Loading
      dispatch(setLoadingV2(true));
      // After a while, jump to next screen
      setTimeout(() => dispatch(setAdCreatorStepNext()), 1000);

      if (fakeMode) {
        // Remove loading
        setTimeout(() => {
          dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST));
        }, 1000);
        return;
      }

      // Call selectconcept
      const body = { template: "final", img: data.visual_image_selected };
      const response = await axios.post(`/generatecreative/${session}`, {
        ...body,
      });

      console.log("response ", response.data);

      // Store responses
      const dataLocal = JSON.parse(JSON.stringify(data));
      dataLocal.download_urls_final = response.data.download_urls;
      dataLocal.zip = response.data.zip;
      dispatch(setData(dataLocal));
      //dispatch(setLoadingV2(false))
      dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST));
    } catch (e) {
      toast.error("Request has failed");
    }
  };

  const downloadURLLandscape =
    data.download_urls.length > 0 ? data.download_urls[0] : sampleLandscape;
  const downloadURLPortrait =
    data.download_urls.length > 1 ? data.download_urls[1] : samplePortrait;
  return (
    <div className="d-flex flex-column silbo-ad-creator-container">
      <SilboAdCreatorStepBar step={props.step} />
      <div
        className="mt-2 flex-grow-1 bg-white d-flex flex-column"
        style={{ borderRadius: "10px", padding: "2em 3em" }}>
        <div className="flex-grow-1 d-flex flex-column">
          <div
            className="fw-bold text-center w-75"
            style={{ fontSize: "32px", margin: "0 auto" }}>
            {t("step_6_creativities_large_title")}
          </div>
          <div
            className="mt-4 d-flex justify-content-center w-100 flex-grow-1"
            style={{ margin: "0 auto" }}>
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-center mt-2">
                <div className="me-2 w-25 pe-2">
                  <img
                    alt=""
                    src={
                      data.model === SILBO_MODELS.USERS
                        ? downloadURLLandscape
                        : downloadURLPortrait
                    }
                    style={{ height: "250px" }}
                  />
                </div>
                <div className="ms-2 w-75 ps-2">
                  <img
                    alt=""
                    src={
                      data.model === SILBO_MODELS.USERS
                        ? downloadURLPortrait
                        : downloadURLLandscape
                    }
                    style={{ height: "250px" }}
                  />
                </div>
              </div>
              <div>
                <div
                  className="silbo-button mt-3 w-50"
                  onClick={(e) => onClick(e)}
                  role="button"
                  style={{ margin: "0 auto" }}>
                  {t("approve")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <SilboAdCreatorFooter />
        </div>
      </div>
    </div>
  );
};
