import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Route, Routes, useNavigate } from "react-router-dom";

import alhambra from "@/assets/img/mahou/landing/alhambra-home.png";
import budweiser from "@/assets/img/mahou/landing/budweiser-home.png";
import fonteide from "@/assets/img/mahou/landing/fonteide-home.png";
import laSalve from "@/assets/img/mahou/landing/la-salve-home.png";
import mixta from "@/assets/img/mahou/landing/mixta-home.png";
import solanDeCabras from "@/assets/img/mahou/landing/solan-de-cabras-home.png";

import ProtectedRoute from "@/components/ProtectedRoute";

import { Landing } from "@/pages/ad_creator/Landing";

import { setModel, setProfile } from "@/redux/store";

import theme from "@/branding/theme";
import { MAHOU_PROFILES } from "@/utils";

const MahouExtraRoutes = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  if (process.env.REACT_APP_CLIENT !== "Mahou") {
    return null;
  }
  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute>
            <Landing
              image={alhambra}
              onClick={() => navigate("/images")}
              onRender={() => {
                dispatch(setProfile(MAHOU_PROFILES.MAHOU_ALHAMBRA));
                dispatch(setModel(theme().initialState.model));
              }}
              text={t("landing_text_1")}
              title={t("landing_title_1")}
            />
          </ProtectedRoute>
        }
        path="/home/alhambra"
      />
      <Route
        element={
          <ProtectedRoute>
            <Landing
              image={budweiser}
              onClick={() => navigate("/images")}
              onRender={() => {
                dispatch(setProfile(MAHOU_PROFILES.MAHOU_BUDWEISER));
                dispatch(setModel(theme().initialState.model));
              }}
              text={t("landing_text_2")}
              title={t("landing_title_2")}
            />
          </ProtectedRoute>
        }
        path="/home/budweiser"
      />
      <Route
        element={
          <ProtectedRoute>
            <Landing
              image={mixta}
              onClick={() => navigate("/images")}
              onRender={() => {
                dispatch(setProfile(MAHOU_PROFILES.MAHOU_MIXTA));
                dispatch(setModel(theme().initialState.model));
              }}
              text={t("landing_text_3")}
              title={t("landing_title_3")}
            />
          </ProtectedRoute>
        }
        path="/home/mixta"
      />
      <Route
        element={
          <ProtectedRoute>
            <Landing
              image={laSalve}
              onClick={() => navigate("/images")}
              onRender={() => {
                dispatch(setProfile(MAHOU_PROFILES.MAHOU_LA_SALVE));
                dispatch(setModel(theme().initialState.model));
              }}
              text={t("landing_text_4")}
              title={t("landing_title_4")}
            />
          </ProtectedRoute>
        }
        path="/home/la-salve"
      />
      <Route
        element={
          <ProtectedRoute>
            <Landing
              image={solanDeCabras}
              onClick={() => navigate("/images")}
              onRender={() => {
                dispatch(setProfile(MAHOU_PROFILES.MAHOU_SOLAN_DE_CABRAS));
                dispatch(setModel(theme().initialState.model));
              }}
              text={t("landing_text_5")}
              title={t("landing_title_5")}
            />
          </ProtectedRoute>
        }
        path="/home/solan-de-cabras"
      />
      <Route
        element={
          <ProtectedRoute>
            <Landing
              image={fonteide}
              onClick={() => navigate("/images")}
              onRender={() => {
                dispatch(setProfile(MAHOU_PROFILES.MAHOU_FONTEIDE));
                dispatch(setModel(theme().initialState.model));
              }}
              text={t("landing_text_6")}
              title={t("landing_title_6")}
            />
          </ProtectedRoute>
        }
        path="/home/fonteide"
      />
    </Routes>
  );
};
export default MahouExtraRoutes;
