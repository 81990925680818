/* eslint-disable react/no-danger */
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
// Font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useState } from "react";

const Suggestion = (props) => {
  const [active, setActive] = useState(false);

  return (
    <div
      className="h-100"
      onClick={() => props.onClick(props.text)}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      role="button">
      <div className="mmtextpro-regular silbo-suggestion h-100">
        <div dangerouslySetInnerHTML={{ __html: props.text }} />
        <div
          className={`angle ${active ? "" : "unactive"}`}
          style={{ position: "absolute", bottom: "5px", right: "5px" }}>
          <FontAwesomeIcon icon={faAngleDown} />
        </div>
      </div>
    </div>
  );
};
export default Suggestion;
