import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import thankYouImg from "@/assets/img/ad_creator/thank_you.svg";

import { SilboAdCreatorStepBar } from "@/components/Ad_creator/StepBar";

import { AD_CREATOR_STEP, setAdCreatorStep } from "@/redux/store";

export const SilboAdCreatorStep7Thankyou = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div
      className="d-flex flex-column silbo-ad-creator-container"
      style={{ background: "var(--main-color)" }}>
      <SilboAdCreatorStepBar step={props.step} />
      <div className="w-100 d-flex justify-content-center align-items-center h-100">
        <div
          className="w-50 bg-white rounded border text-center"
          style={{ padding: "3em 5em" }}>
          <div className="fw-bold" style={{ fontSize: "24px" }}>
            {t("congratulations")}
          </div>
          <div>{t("congratulations_description")}</div>
          <img alt="" src={thankYouImg} />
          <div
            className="silbo-button w-50"
            onClick={() =>
              dispatch(setAdCreatorStep(AD_CREATOR_STEP.STEP_1_WELCOME))
            }
            role="button"
            style={{ margin: "0 auto", marginTop: "3em" }}>
            {t("restart")}
          </div>
        </div>
      </div>
    </div>
  );
};
