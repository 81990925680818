import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import SilboAdCreatorStepSection from "@/components/Ad_creator/StepSection";

export const SilboAdCreatorStepBar = () => {
  const { t } = useTranslation();
  const adCreatorStep = useSelector((state) => state.adCreator.step);
  const isMid = useSelector((state) => state.adCreator.isMid);
  const stepNumber = parseInt(adCreatorStep.split("_")[1]);
  // const isMid = adCreatorStep.includes('MID');
  return (
    <div className="d-flex p-3 bg-white silbo-progress-bar-container">
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ width: "15%", borderRight: "1px solid black" }}>
        <div className="fw-bold">
          <div>Silbö</div>
          <div style={{ fontSize: "25px" }}>{t("ad_creator")}</div>
        </div>
      </div>
      <div className="d-flex justify-content-between flex-grow-1 ps-4 pe-2">
        <div className="d-flex align-items-center" style={{ width: "20%" }}>
          <SilboAdCreatorStepSection
            stepNumber={stepNumber}
            stepTarget={3}
            title={t("step_3_briefing_title")}
          />
        </div>
        <div className="d-flex align-items-center" style={{ width: "20%" }}>
          <SilboAdCreatorStepSection
            isMid={isMid}
            stepNumber={stepNumber}
            stepTarget={4}
            title={t("step_4_copies_title")}
          />
        </div>
        <div className="d-flex align-items-center" style={{ width: "20%" }}>
          <SilboAdCreatorStepSection
            stepNumber={stepNumber}
            stepTarget={5}
            title={t("step_5_visuals_title")}
          />
        </div>
        <div className="d-flex align-items-center" style={{ width: "20%" }}>
          <SilboAdCreatorStepSection
            stepNumber={stepNumber}
            stepTarget={6}
            textWidth="122px"
            title={t("step_6_creativities_title")}
          />
        </div>
        <div className="d-flex align-items-center" style={{ width: "20%" }}>
          <SilboAdCreatorStepSection
            isLast
            stepNumber={stepNumber}
            stepTarget={7}
            title={t("step_7_export_title")}
          />
        </div>
      </div>
    </div>
  );
};
