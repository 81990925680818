import FastClock from "@/assets/img/fast-clock.svg";
import IconImage from "@/assets/img/img_icon_white.svg";
import LockIcon from "@/assets/img/lock_icon.svg";

import "./CardApp.css";

/**
 * CardApp component.
 * @param {Object} props - This component recive this props:.
 * @param {string} props.text - The text of the card.
 * @param {string} props.image - The background image of the card.
 * @param {string} props.auxiliaryText - The auxiliary text.
 * @param {boolean} props.isForText - Icon text is showed?
 * @param {boolean} props.isForImage - Icon image is showed?
 * @param {boolean} props.available - Ready to use?
 * @returns {JSX.Element} The CardApp component.
 */
const CardApp = (props) => {
  const {
    text,
    isForText,
    isForImage,
    textForBlur,
    available,
    onClick,
    image,
    auxiliaryText,
  } = props;

  return available ? (
    <div className="card-app-container" onClick={onClick}>
      <img alt="background" className="card-app-image" src={image} />
      <div className="card-app-image-mask" />
      {textForBlur ? (
        <div className="card-app-blur-text">
          <img alt="fast-clock" className="card-app-clock" src={FastClock} />
          <p>{textForBlur}</p>
        </div>
      ) : null}
      <div className="card-app-content-container">
        <div className="card-app-content">
          <h5>{text}</h5>
          <div className="d-flex gap-3 card-app-insights">
            {isForImage ? (
              <>
                <img alt="icon" className="card-app-icon" src={IconImage} />
                {isForText ? <p>+</p> : null}
              </>
            ) : null}
            {isForText ? <p>T</p> : null}
          </div>
        </div>
        <p className="auxiliary-text">{auxiliaryText}</p>
      </div>
    </div>
  ) : (
    <div className="locked-card-container">
      <img alt="background" className="locked-card-image" src={image} />
      <div className="locked-card-image-mask" />
      <div className="locked-card-overlay">
        <img alt="lock" className="locked-card-lock-icon" src={LockIcon} />
        <h5 className="locked-card-coming-soon-text">Proximamente...</h5>
      </div>
      <p className="locked-card-auxiliary-text">{auxiliaryText}</p>
    </div>
  );
};

export default CardApp;
