import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { useState } from "react";

import backArrow from "@/assets/img/ad_creator/back_arrow.svg";
import { ReactComponent as PencilEdit } from "@/assets/img/ad_creator/pencil_edit.svg";
import regenerateImage from "@/assets/img/ad_creator/regenerate.svg";
import regenerateHoverImage from "@/assets/img/ad_creator/regenerate_hover.svg";
import downloadImage from "@/assets/img/download-white.svg";
import downloadHoverImage from "@/assets/img/download_hover.svg";

import { setAdCreatorStepPrevious } from "@/redux/store";

export const SilboAdCreatorFooter = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [regenerateHover, setRegenerateHover] = useState(false);
  const [downloadHover, setDownloadHover] = useState(false);
  const [editHover, setEditHover] = useState(false);

  const onClick = (e) => {
    e.stopPropagation();
    dispatch(setAdCreatorStepPrevious());
  };
  const enabled = props.onRegenerate != null;
  const donwloadEnabled = props.onDownload != null;
  const editEnabled = !!props.onEdit;
  return (
    <div className="d-flex justify-content-between align-items-center w-100">
      <div onClick={(e) => onClick(e)} role="button">
        <img alt="" src={backArrow} />
        <label
          className="ms-3 fw-bold"
          role="button"
          style={{ color: "#7D7D7D" }}>
          {t("back")}
        </label>
      </div>
      <div className="d-flex align-items-center gap-3">
        <div
          className={`${enabled ? "" : "d-none"}`}
          onClick={() => props.onRegenerate()}
          onMouseEnter={() => setRegenerateHover(true)}
          onMouseLeave={() => setRegenerateHover(false)}
          role="button">
          <img
            alt=""
            src={regenerateHover ? regenerateHoverImage : regenerateImage}
          />
        </div>
        <div
          className={`${donwloadEnabled ? "d-flex justify-content-center align-items-center" : "d-none"}`}
          onClick={() => props.onDownload()}
          onMouseEnter={() => setDownloadHover(true)}
          onMouseLeave={() => setDownloadHover(false)}
          role="button"
          style={{
            borderRadius: "50%",
            backgroundColor: downloadHover ? "var(--main-color)" : "#064a3d",
            height: "65px",
            width: "65px",
          }}>
          <img
            alt=""
            height="24px"
            src={downloadHover ? downloadHoverImage : downloadImage}
            width="24px"
          />
        </div>
        <div
          className={`${editEnabled ? "d-flex justify-content-center align-items-center" : "d-none"}`}
          onClick={() => props.onEdit()}
          onMouseEnter={() => setEditHover(true)}
          onMouseLeave={() => setEditHover(false)}
          role="button"
          style={{
            borderRadius: "50%",
            backgroundColor: editHover ? "var(--main-color)" : "#064a3d",
            height: "65px",
            width: "65px",
          }}>
          <PencilEdit
            color={editHover ? "var(--main-color-hover-dark)" : "#fff"}
            height="24px"
            width="24px"
          />
        </div>
      </div>
    </div>
  );
};

export default SilboAdCreatorFooter;
