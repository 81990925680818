import CameraArlo from "@/assets/img/securitas/Securitas_products/alto/Producto_Camara-Arlo.png";
import CameraPro from "@/assets/img/securitas/Securitas_products/alto/Producto_Camera_Pro.png";
import Cerradura from "@/assets/img/securitas/Securitas_products/alto/Producto_Cerradura.png";
import Detector1 from "@/assets/img/securitas/Securitas_products/alto/Producto_Dector3.png";
import Detector2 from "@/assets/img/securitas/Securitas_products/alto/Producto_Detector.png";
import Detector3 from "@/assets/img/securitas/Securitas_products/alto/Producto_Detector_2.png";
import Fotodetector from "@/assets/img/securitas/Securitas_products/alto/Producto_Fotodetector.png";
import Llaves from "@/assets/img/securitas/Securitas_products/alto/Producto_Llaves.png";
import Mando from "@/assets/img/securitas/Securitas_products/alto/Producto_Mando.png";
import Panel1 from "@/assets/img/securitas/Securitas_products/alto/Producto_Panel.png";
import Placas from "@/assets/img/securitas/Securitas_products/alto/Producto_Placas.png";
import Pulsador from "@/assets/img/securitas/Securitas_products/alto/Producto_Pulsador.png";
import Sentimel from "@/assets/img/securitas/Securitas_products/alto/Producto_Sentimel.png";
import Zerovision from "@/assets/img/securitas/Securitas_products/alto/Producto_Zerovision.png";
import Unidad from "@/assets/img/securitas/Securitas_products/alto/Producto_unidad.png";
import Panel2 from "@/assets/img/securitas/Securitas_products/alto/Proudcto_Panel_exterior.png";
import BodegonA from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_A.png";
import BodegonB from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_B.png";
import BodegonC from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_C.png";
import BodegonD from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_D.png";
import BodegonE from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_E.png";
import BodegonF2 from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_F2.png";
import BodegonF from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_F.png";
import BodegonG from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_G.png";
import BodegonH from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_H.png";
import BodegonI from "@/assets/img/securitas/Securitas_products/bodegon/Bodegon_I.png";

import { SECURITAS_MODELS } from "@/utils";

const getSecuritaProductsByProfile = (profile) => {
  const alto_detalle = [
    {
      aux: "Arlo Security Pro",
      id: "1",
      name: "CAMARA",
      image: CameraArlo,
    },
    {
      aux: "Arlo Security Pro",
      id: "2",
      name: "CAMARA",
      image: CameraPro,
    },
    {
      aux: "Inteligente",
      id: "3",
      name: "CERRADURA",
      image: Cerradura,
    },
    {
      aux: "de acceso",
      id: "4",
      name: "DETECTOR",
      image: Detector1,
    },
    {
      aux: "de incendios",
      id: "5",
      name: "DETECTOR",
      image: Detector2,
    },
    {
      aux: "Perimetral",
      id: "6",
      name: "DETECTOR",
      image: Detector3,
    },
    {
      aux: "",
      id: "7",
      name: "FOTODETECTOR",
      image: Fotodetector,
    },
    {
      aux: "Inteligentes",
      id: "8",
      name: "LLAVES",
      image: Llaves,
    },
    {
      aux: "a distancia",
      id: "9",
      name: "MANDO",
      image: Mando,
    },
    {
      aux: "de control",
      id: "10",
      name: "PANEL",
      image: Panel1,
    },
    {
      aux: "de control exterior",
      id: "11",
      name: "PANEL",
      image: Panel2,
    },
    {
      aux: "disuasorias",
      id: "12",
      name: "PLACAS",
      image: Placas,
    },
    {
      aux: "SOS",
      id: "13",
      name: "PULSADOR",
      image: Pulsador,
    },
    {
      aux: "V2",
      id: "14",
      name: "SENTIMEL",
      image: Sentimel,
    },
    {
      aux: "central",
      id: "15",
      name: "UNIDAD",
      image: Unidad,
    },
    {
      aux: "",
      id: "16",
      name: "ZEROVISION",
      image: Zerovision,
    },
  ];

  const bodegon = [
    {
      id: "1",
      name: "Bodegon A",
      image: BodegonA,
    },
    {
      id: "2",
      name: "Bodegon B",
      image: BodegonB,
    },
    {
      id: "3",
      name: "Bodegon C",
      image: BodegonC,
    },
    {
      id: "4",
      name: "Bodegon D",
      image: BodegonD,
    },
    {
      id: "5",
      name: "Bodegon E",
      image: BodegonE,
    },
    {
      id: "6",
      name: "Bodegon F",
      image: BodegonF,
    },
    {
      id: "7",
      name: "Bodegon F2",
      image: BodegonF2,
    },
    {
      id: "8",
      name: "Bodegon G",
      image: BodegonG,
    },
    {
      id: "9",
      name: "Bodegon H",
      image: BodegonH,
    },
    {
      id: "10",
      name: "Bodegon I",
      image: BodegonI,
    },
  ];
  switch (profile) {
    case SECURITAS_MODELS.ALTO_DETALLE:
      return alto_detalle;
    case SECURITAS_MODELS.BODEGON:
      return bodegon;

    default:
      return [];
  }
};
export default getSecuritaProductsByProfile;
