import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useEffect } from "react";

import adCreator_banner from "@/assets/img/adCreator_banner.png";

import { SilboAdCreatorStep2Profile } from "@/pages/Ad_creator/SilboAdCreatorStep2Profile";
import { SilboAdCreatorStep3Briefing } from "@/pages/Ad_creator/SilboAdCreatorStep3Briefing";
import SilboAdCreatorStep4CopiesElaborate from "@/pages/Ad_creator/SilboAdCreatorStep4Copies/CopiesElaborate";
import { SilboAdCreatorStep4Copies } from "@/pages/Ad_creator/SilboAdCreatorStep4Copies/SilboAdCreatorStep4Copies";
import { SilboAdCreatorStep5Visuals } from "@/pages/Ad_creator/SilboAdCreatorStep5Visuals";
import { SilboAdCreatorStep6Creativities } from "@/pages/Ad_creator/SilboAdCreatorStep6Creativities";
import { SilboAdCreatorStep7Export } from "@/pages/Ad_creator/SilboAdCreatorStep7Export";
import { SilboAdCreatorStep7Thankyou } from "@/pages/Ad_creator/SilboAdCreatorStep7Thankyou";
// Steps
import { Landing } from "@/pages/ad_creator/Landing";

import {
  AD_CREATOR_STEP,
  setAdCreatorStep,
  setAdCreatorStepNext,
} from "@/redux/store";
import { setAdCreatorSession } from "@/redux/store";

import { api } from "@/service/instance";
import { SILBO_MODELS } from "@/utils";

import "./SilboAdCreatorAssistant.css";

export const SilboAdCreatorAssistant = () => {
  const step = useSelector((state) => state.adCreator.step);
  const model = useSelector((state) => state.adCreator.data.model);
  const dispatch = useDispatch();

  const generateSession = async () => {
    const apiModel =
      model === SILBO_MODELS.STABLE_DIFUSION
        ? "adcreative_silby"
        : model === SILBO_MODELS.COMPANIES
          ? "adcreative_empresas"
          : "adcreative_particulares";
    try {
      // Create new session
      const response = await api(`/new?character=${apiModel}`);
      dispatch(setAdCreatorSession(response.data.session_id));
    } catch (e) {
      toast.error("Request has failed");
      dispatch(setAdCreatorStep(AD_CREATOR_STEP.STEP_2_PROFILE));
    }
  };

  useEffect(() => {
    dispatch(setAdCreatorStep(AD_CREATOR_STEP.STEP_1_WELCOME));
  }, [dispatch]);

  useEffect(() => {
    if (model) {
      generateSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  return (
    <>
      {step === AD_CREATOR_STEP.STEP_1_WELCOME ? (
        <Landing
          image={adCreator_banner}
          onClick={() => dispatch(setAdCreatorStepNext())}
        />
      ) : null}
      {step === AD_CREATOR_STEP.STEP_2_PROFILE ? (
        <SilboAdCreatorStep2Profile />
      ) : null}
      {step === AD_CREATOR_STEP.STEP_3_BRIEFING ? (
        <SilboAdCreatorStep3Briefing />
      ) : null}
      {step === AD_CREATOR_STEP.STEP_4_COPIES ? (
        <SilboAdCreatorStep4Copies />
      ) : null}
      {step === AD_CREATOR_STEP.STEP_4_COPIES_ELABORATE ? (
        <SilboAdCreatorStep4CopiesElaborate />
      ) : null}
      {step === AD_CREATOR_STEP.STEP_5_VISUALS ? (
        <SilboAdCreatorStep5Visuals />
      ) : null}
      {step === AD_CREATOR_STEP.STEP_6_CREATIVITIES ? (
        <SilboAdCreatorStep6Creativities />
      ) : null}
      {step === AD_CREATOR_STEP.STEP_7_EXPORT ? (
        <SilboAdCreatorStep7Export />
      ) : null}
      {step === AD_CREATOR_STEP.STEP_7_THANKYOU ? (
        <SilboAdCreatorStep7Thankyou />
      ) : null}
    </>
  );
};
