import { Placeholder } from "react-bootstrap";

import { useState } from "react";

import "./Image.css";

const Image = ({ src, alt, className, height }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div
      className="main-image-container"
      style={{
        position: "relative",
        width: "100%",
        minHeight: height,
        height: "100%",
      }}>
      <img
        alt={alt}
        className={`main-image-placeholder ${className}`}
        onLoad={() => {
          setIsLoading(false);
        }}
        src={src}
        style={{
          height: isLoading ? height : "auto",
          visibility: !isLoading ? undefined : "hidden",
          position: isLoading ? undefined : "relative",
        }}
      />
      <Placeholder
        animation="glow"
        as="div"
        className="main-image-placeholder"
        height="100%"
        style={{
          display: isLoading ? undefined : "none",
        }}>
        <Placeholder className="custom-placeholder-image" xs={12} />
      </Placeholder>
    </div>
  );
};
export default Image;
