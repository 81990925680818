import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { setAdCreatorStepNext, setData } from "@/redux/store";

import { SILBO_MODELS } from "@/utils";

export const SilboAdCreatorStep2Profile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.adCreator.data);

  const onClick = (model) => {
    dispatch(setData({ ...data, model }));
    dispatch(setAdCreatorStepNext());
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100">
      <div style={{ width: "40%", margin: "0 auto" }}>
        <div className="silbo-step-title text-center">
          {t("define_your_client")}
        </div>
        <div className="mt-5">
          <div
            className="silbo-button-gray mt-3"
            onClick={() => onClick(SILBO_MODELS.USERS)}>
            {t("particulars")}
          </div>
          <div
            className="silbo-button-gray mt-3"
            onClick={() => onClick(SILBO_MODELS.COMPANIES)}>
            {t("companies")}
          </div>
          <div
            className="silbo-button-gray mt-3"
            onClick={() => onClick(SILBO_MODELS.STABLE_DIFUSION)}>
            {t("generic")}
          </div>
        </div>
      </div>
    </div>
  );
};
