import axios from "axios";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useEffect, useRef, useState } from "react";

import fake_response from "@/assets/fake_response.json";
import HistoryImage from "@/assets/img/history.svg";
import { ReactComponent as PromptLibraryImage } from "@/assets/img/prompt-library.svg";
import pencilIcon from "@/assets/img/sidebar-pencil.svg";

import AspectRatioSelector, {
  ASPECT_RATIO,
} from "@/components/AspectRatioSelector";
import DownloadButton from "@/components/DownloadButton";
import DragAndDropFile from "@/components/DropFile/DropFile";
import HistorySideBar from "@/components/HistorySideBar/HistorySideBar";
import Image from "@/components/Image/Image";
import ProductSelector from "@/components/ProductAssistant/ProductSelector";
import { PromptManager } from "@/components/PromptLibrary";
import { SilboSendButton } from "@/components/SilboSendButton";
import UserInfo from "@/components/UserInfo/UserInfo";

import { useInterval } from "@/hooks/useInterval";

import {
  LOADING_V2_MODE,
  setLoadingV2,
  setLoadingV2Mode,
  setMaxWidth,
  setModel,
  setProfile,
  setSession,
} from "@/redux/store";

import { maxWidthValue, minWidthValue } from "@/utils/sidebar";
import getSideBarDate from "@/utils/sidebar-date";

import theme from "@/branding/theme";
import { api } from "@/service/instance";
import {
  useGetSasTokenQuery,
  useGetUserQuery,
  useLazyGetSessionQuery,
} from "@/service/user";
import {
  IMAGES_TYPES,
  MAHOU_MODELS,
  SECURITAS_MODELS,
  SILBO_MODELS,
  isDesktop,
} from "@/utils";

import ProductAssistant from "../../components/ProductAssistant/ProductAssistant";
import "./SilboImageAssistant.css";

export const SilboImageAssistant = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();

  const maxWidth = useSelector((state) => state.main.sidebarMaxWidth);
  const [imageList, setImageList] = useState([
    // {author: AUTHOR.ME, data: {positive: 'animal', negative: 'perro'} },
    // {author: AUTHOR.BOT, data: [...fake_response], positive: 'animal', negative: 'perro' },
  ]);
  const [prompt, setPrompt] = useState({ positive: "", negative: "" });
  const [showPromptManager, setShowPromptManager] = useState(false);
  const [positiveFocus, setPositiveFocus] = useState(false);
  const [negativeFocus, setNegativeFocus] = useState(false);
  const [positiveTextareaOverflow, setPositiveTextareaOverflow] =
    useState(false);
  const [negativeTextareaOverflow, setNegativeTextareaOverflow] =
    useState(false);
  const [initialPositiveScrollHeight, setInitialPositiveScrollHeight] =
    useState();
  const [initialNegativeScrollHeight, setInitialNegativeScrollHeight] =
    useState();
  const [selectedProduct, setSelectedProduct] = useState(undefined);

  const sideBarIsOpen = maxWidth === maxWidthValue;

  const positiveMessageTextAreaRef = useRef();
  const negativeMessageTextAreaRef = useRef();
  const messageTextAreaRef = useRef();
  const messageListContainer = useRef();
  const aspectRatioRef = useRef(ASPECT_RATIO.RATIO_16_9);
  const sesionOnState = useSelector((state) => state.main.session);
  const fakeMode = useSelector((state) => state.main.fakeMode);
  const model = useSelector((state) => state.main.model);
  const profile = useSelector((state) => state.main.profile);
  const positivePropmtPlaceholder = () => {
    switch (model) {
      case SILBO_MODELS.STABLE_DIFUSION:
        return t("message_positive_placeholder_silby");
      case SILBO_MODELS.USERS:
        return t("message_positive_placeholder_users");
      case SILBO_MODELS.COMPANIES:
        return t("message_positive_placeholder_companies");
      default:
        return t("message_positive_placeholder_silby");
    }
  };
  const selectedProfile = theme().appBarProfiles.profileList.find(
    (p) => p.value === profile,
  );
  const selectedModel = selectedProfile?.subItems.find(
    (m) => m.value === model,
  );

  const { data: sasToken } = useGetSasTokenQuery();
  const getUserResult = useGetUserQuery();
  const [getSessionQuery, getSessionResult] = useLazyGetSessionQuery();

  // -------------------
  // Handlers
  // -------------------

  const createSession = async (modelString) => {
    // Select description according to mode
    const response = await api(
      `/new?character=${modelString ? modelString : model === SILBO_MODELS.STABLE_DIFUSION ? "silby-img" : "particularesyempresas-img"}`,
    );
    dispatch(setSession(response.data.session_id));
    return response.data.session_id;
  };

  const onKeyUpMessage = (e) => {
    e.preventDefault();
    if (e.key === "Enter" && e.shiftKey === false) {
      // Remove CR/LF if at the end
      const promptLocal = JSON.parse(JSON.stringify(prompt));
      if (promptLocal.positive[prompt.positive.length - 1] === "\n")
        promptLocal.positive = promptLocal.positive.substring(
          0,
          promptLocal.positive.length - 1,
        );
      if (promptLocal.negative[prompt.negative.length - 1] === "\n")
        promptLocal.negative = promptLocal.negative.substring(
          0,
          promptLocal.negative.length - 1,
        );

      // Trigger promptLocal
      onSend(promptLocal);
      return;
    }
  };

  const onSend = async (promptLocal) => {
    if (selectedModel.type === IMAGES_TYPES.PRODUCT) {
      dispatch(setLoadingV2(true));

      const createProductSession = async () => {
        const response = await api.get("/new?character=product");
        dispatch(setSession(response.data.session_id));
        return response.data.session_id;
      };

      const sessionId = await createProductSession();

      const imageProduct = await api.post(`/product/${sessionId}`, {
        product_filename:
          selectedProduct?.id === "2"
            ? "2022_09_01_Alh_Reserva1925_Botella_Mini_SinGotas.jpeg"
            : selectedProduct?.id === "3"
              ? "Alh_Especial_Botella_Normal.png"
              : "Alh_Reserva1925_Botella_Normal.png",
        // product_filename: "Alh_Reserva1925_Botella_Normal.png",
        p: prompt.positive.trim(),
      });

      const imageList = [];
      imageList.push({
        data: imageProduct.data.images,
        positive: prompt.positive,
        negative: prompt.negative,
        upscalable: true,
      });
      setImageList(imageList);
      dispatch(setLoadingV2(false));
      getUserResult.refetch();
      navigate(`/images/${sessionId}`);
      return;
    }

    // Do not send empty messages
    if (promptLocal.positive.length === 0) return;

    // Proceed (give a little for the fading in)
    const imageListLocal = JSON.parse(JSON.stringify(imageList));

    // Simulate loading
    dispatch(setLoadingV2(true));

    if (fakeMode) {
      imageListLocal.push({
        data: [...fake_response],
        positive: prompt.positive,
        negative: prompt.negative,
        upscalable: true,
      });
      setImageList([...imageListLocal]);
      dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST));
      setPrompt({ positive: "", negative: "" });
      return;
    }

    const SIZE_LIST = {
      LANDSCAPE_1536x640: "1536x640",
      LANDSCAPE_1344x768: "1344x768",
      LANDSCAPE_1216x832: "1216x832",
      LANDSCAPE_1152x896: "1152x896",
      SQUARE_1024x1024: "1024x1024",
      PORTRAIT_896x1152: "896x1152",
      PORTRAIT_832x1216: "832x1216",
      PORTRAIT_768x1344: "768x1344",
      PORTRAIT_640x1536: "640x1536",
    };

    // 1:1 - 1024x1024
    // 16:9 - 1344x768
    // 4:5 - 896x1152
    // 2:3 - 832x1248
    let size = null;
    if (aspectRatioRef.current === ASPECT_RATIO.RATIO_16_9)
      size = SIZE_LIST.LANDSCAPE_1344x768;
    if (aspectRatioRef.current === ASPECT_RATIO.RATIO_1_1)
      size = SIZE_LIST.SQUARE_1024x1024;
    if (aspectRatioRef.current === ASPECT_RATIO.RATIO_2_3)
      size = SIZE_LIST.PORTRAIT_832x1216;
    if (aspectRatioRef.current === ASPECT_RATIO.RATIO_4_5)
      size = SIZE_LIST.PORTRAIT_896x1152;

    let session = params.id;
    if (!session) {
      session = await createSession(
        selectedModel.value === MAHOU_MODELS.LIFE_STYLE
          ? "alhambra-character"
          : undefined,
      );
    }

    const body = {
      size,
      p: promptLocal.positive,
      np: promptLocal.negative,
      img_batch_size: 2,
    };
    // Request for response
    const response = await api.post(
      `/getimage/${session}?character=${
        selectedModel.value === MAHOU_MODELS.LIFE_STYLE
          ? "alhambra-character"
          : model === SILBO_MODELS.STABLE_DIFUSION
            ? "silby-img"
            : "particularesyempresas-img"
      }`,
      body,
    );
    imageListLocal.push({
      data: response.data.images,
      positive: prompt.positive,
      negative: prompt.negative,
      upscalable: true,
    });
    setImageList([...imageListLocal]);
    getUserResult.refetch();
    navigate(`/images/${session}`);
    dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST));
    setPrompt({ positive: "", negative: "" });
  };

  const onUpscale = async (filename) => {
    dispatch(setLoadingV2(true));
    // Request for response
    const response = await axios.get(
      `/upscaleimg/${params.id ? params.id : sesionOnState ? sesionOnState : ""}?filename=${filename}`,
    );

    const imageListLocal = JSON.parse(JSON.stringify(imageList));

    const data = [{ name: filename, value: response.data.data }];

    imageListLocal.push({
      data: data,
      positive: prompt.positive,
      negative: prompt.negative,
      upscalable: false,
    });
    setImageList(imageListLocal);

    dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST));
  };

  const onPromptLibrarySelected = (prompt) => {
    setShowPromptManager(false);
    setPrompt({ positive: prompt, negative: "" });
  };

  const groupByDate = (data) => {
    if (!data) return;
    const formated = [...data].reverse().reduce((acc, item) => {
      const date = item.timestamp.split(" ")[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(item);
      return acc;
    }, {});
    return formated;
  };

  const content = theme().image.content(model, profile);

  // -------------------
  // Hooks
  // -------------------

  useEffect(() => {
    if (selectedModel && selectedModel.type === IMAGES_TYPES.EDITOR) return;
    if (messageListContainer.current == null) return;
    const scrollHeight = messageListContainer.current.scrollHeight
      ? messageListContainer.current.scrollHeight
      : 0;

    const height = messageListContainer.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    messageListContainer.current.scrollTop =
      maxScrollTop > 0 ? maxScrollTop : 0;
  }, [imageList, selectedModel]);

  // useEffect(() => {
  //   if (selectedModel && selectedModel.type === IMAGES_TYPES.EDITOR) return;
  //   setInitialPositiveScrollHeight(
  //     positiveMessageTextAreaRef?.current?.scrollHeight,
  //   );
  //   setInitialNegativeScrollHeight(
  //     negativeMessageTextAreaRef?.current?.scrollHeight,
  //   );
  // }, [selectedModel]);

  // useInterval(() => {
  //   if (selectedModel && selectedModel.type === IMAGES_TYPES.EDITOR) return;
  //   if (!positiveMessageTextAreaRef.current) return;

  //   const isPositiveOverflow =
  //     positiveMessageTextAreaRef.current.scrollHeight > 52;

  //   const isNegativeOverflow =
  //     negativeMessageTextAreaRef.current.scrollHeight >
  //     initialNegativeScrollHeight;

  //   // When textarea WAS overflow and is now NOT overflow
  //   if (positiveTextareaOverflow === true && isPositiveOverflow === false) {
  //     setPositiveTextareaOverflow(false);
  //   }
  //   if (negativeTextareaOverflow === true && isNegativeOverflow === false) {
  //     setNegativeTextareaOverflow(false);
  //   }

  //   console.log(positiveTextareaOverflow, isPositiveOverflow);
  //   // When textarea WAS NOT overflow and is now overflow
  //   if (positiveTextareaOverflow === false && isPositiveOverflow === true) {
  //     setPositiveTextareaOverflow(true);
  //   }
  //   if (negativeTextareaOverflow === false && isNegativeOverflow === true) {
  //     setNegativeTextareaOverflow(true);
  //   }
  // }, 500);

  useEffect(() => {
    if (positiveMessageTextAreaRef.current == null) return;
    if (positiveTextareaOverflow && prompt.positive.length > 0) return;
    const scrollHeight = positiveMessageTextAreaRef.current.scrollHeight;
    const height = positiveMessageTextAreaRef.current.clientHeight;
    const maxScrollTop = scrollHeight - height;
    setPositiveTextareaOverflow(maxScrollTop > 0);
  }, [positiveTextareaOverflow, prompt.positive]);

  useEffect(() => {
    if (params.id) {
      setImageList([]);
      getSessionQuery({ id: params.id, type: "img_generator" });
    }
  }, [getSessionQuery, params.id]);

  useEffect(() => {
    if (getSessionResult.data) {
      const imageListLocal = [];
      getSessionResult.data.conversation.map((item) => {
        imageListLocal.push({
          data: item.images,
          positive: item.prompt,
          negative: "",
          upscalable: true,
        });
      });
      if (getSessionResult.data.conversation.length > 0) {
        // if (getSessionResult.data.conversation[0].prompt === "" && params.id) {
        //   dispatch(setModel(IMAGES_TYPES.EDITOR));
        // }
        if (!profile) {
          dispatch(
            setProfile(
              theme().appBarProfiles.profileList.find(
                (p) => p.path === "/images",
              ).value,
            ),
          );
        }
      }
      setImageList([...imageListLocal]);
      setPrompt({ positive: "", negative: "" });
    }
  }, [
    dispatch,
    getSessionResult.data,
    getSessionResult.requestId,
    params.id,
    profile,
  ]);

  useEffect(() => {
    if (params.id) {
      dispatch(setSession(params.id));
    } else {
      dispatch(setSession(undefined));
    }
    setImageList([]);
  }, [dispatch, params.id]);

  useEffect(() => {
    if (!selectedModel) dispatch(setModel(theme().initialState.model));
  }, [dispatch, selectedModel]);

  useEffect(() => {
    setImageList([]);
  }, [model, profile]);

  // -------------------
  // Rendering
  // -------------------

  const groupedData = groupByDate(getUserResult.data?.user.imageSession);

  return (
    <div className="d-flex flex-column h-100">
      {getSessionResult.isFetching || getSessionResult.isLoading ? (
        <div className="silbo-title-container silbo-title-container-image-assist d-flex">
          <div className="h-100 w-100 d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status" />
          </div>
        </div>
      ) : null}

      {imageList.length === 0 &&
      !getSessionResult.isFetching &&
      !getSessionResult.isLoading &&
      !!selectedModel &&
      !!selectedProfile ? (
        <>
          {selectedModel.type === IMAGES_TYPES.PRODUCT ? (
            <div className="silbo-title-container-image-assist mx-auto d-flex flex-column justify-content-center align-items-center">
              <ProductAssistant
                products={
                  theme().login.case !== "Mahou" &&
                  selectedProfile.subItems &&
                  selectedProfile.subItems.length >= 1
                    ? selectedModel.products
                    : selectedProfile.products
                }
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                type={
                  selectedModel.value === SECURITAS_MODELS.BODEGON
                    ? true
                    : false
                }
              />
            </div>
          ) : null}

          {selectedModel.type === IMAGES_TYPES.IMAGE ? (
            <div className="silbo-title-container silbo-title-container-image-assist justify-content-center align-items-center">
              <div className="silbo-title">{t(content.title)}</div>
              <img
                alt={content.title}
                className="image-main-image"
                src={content.image}
              />
            </div>
          ) : null}
          {selectedModel.type === IMAGES_TYPES.EDITOR ? (
            <div className="silbo-title-container-image-assist-editor  mx-auto d-flex flex-column justify-content-center align-items-center">
              <DragAndDropFile />
            </div>
          ) : null}
        </>
      ) : null}

      {imageList.length > 0 &&
      sasToken &&
      !getSessionResult.isFetching &&
      !getSessionResult.isLoading ? (
        <div
          className={`silbo-title-container silbo-title-container-image-assist image-assist-content-container ${selectedModel?.type === IMAGES_TYPES.EDITOR ? "silbo-title-container-image-assist-edited-image" : ""}`}
          ref={messageListContainer}>
          {imageList.map((item, idx) => (
            <div className="pb-3" key={idx}>
              <div className="img-container">
                <div className="fst-italic" style={{ color: "#6A6A6A" }}>
                  {item.positive}
                </div>
                <div
                  className={item.upscalable ? "image-session-container" : ""}>
                  {item.data.map((imageItem, idx) => (
                    <div
                      className="d-flex flex-column gap-2 justify-stretch"
                      key={idx}>
                      <Image
                        alt=""
                        className="w-100 image-preview conversation"
                        height="11.5rem"
                        src={
                          item.upscalable
                            ? `${imageItem.value}?${sasToken}`
                            : `data:image/jpeg;base64,${imageItem.value}`
                        }
                      />
                      <div className="d-flex mt-2">
                        {item.upscalable ? (
                          <div
                            className="download me-1 ps-2 pe-2"
                            onClick={() => onUpscale(imageItem.name)}
                            role="button"
                            style={{ width: "initial" }}>
                            Upscale
                          </div>
                        ) : null}
                        <DownloadButton
                          value={
                            item.upscalable
                              ? `${imageItem.value}?${sasToken}`
                              : `data:image/png;base64,${imageItem.value}`
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  className="mt-3"
                  style={{
                    width: "100%",
                    height: "2px",
                    background: "#C5C5C5",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      ) : null}

      {selectedModel && selectedModel.type !== IMAGES_TYPES.EDITOR ? (
        <div className="w-100 text-center d-flex justify-content-center align-items-end silbo-input-layer">
          <div className="d-flex justify-content-center">
            <div style={{ width: "729px" }}>
              <div className="silbo-prompt-library-aspectradio d-flex justify-content-between">
                <AspectRatioSelector
                  onChange={(aspect_ratio) =>
                    (aspectRatioRef.current = aspect_ratio)
                  }
                />
                {selectedModel?.type === IMAGES_TYPES.PRODUCT &&
                selectedProduct ? (
                  <ProductSelector
                    products={
                      theme().login.case !== "Mahou" &&
                      selectedProfile.subItems &&
                      selectedProfile.subItems.length >= 1
                        ? selectedModel.products
                        : selectedProfile.products
                    }
                    selectedProduct={selectedProduct}
                    setSelectedProduct={setSelectedProduct}
                  />
                ) : null}
              </div>
              <div
                className={`silbo-input-container postive ${prompt.positive.length > 0 ? "active" : ""} ${positiveTextareaOverflow ? "bigTextarea" : ""}`}>
                <textarea
                  className="silbo-input"
                  onBlur={() => setPositiveFocus(false)}
                  onChange={(e) =>
                    setPrompt({ ...prompt, positive: e.target.value })
                  }
                  onFocus={() => setPositiveFocus(true)}
                  onKeyUp={(e) => onKeyUpMessage(e)}
                  placeholder={positivePropmtPlaceholder()}
                  ref={positiveMessageTextAreaRef}
                  type="text"
                  value={prompt.positive}
                />
              </div>
              <div
                className={`silbo-input-container negative ${prompt.negative.length > 0 ? "active" : ""} ${negativeFocus && negativeTextareaOverflow ? "bigTextarea" : ""}`}>
                <textarea
                  className="silbo-input"
                  onBlur={() => setNegativeFocus(false)}
                  onChange={(e) =>
                    setPrompt({ ...prompt, negative: e.target.value })
                  }
                  onFocus={() => setNegativeFocus(true)}
                  onKeyUp={(e) => onKeyUpMessage(e)}
                  placeholder={t("message_negative_placeholder")}
                  ref={negativeMessageTextAreaRef}
                  type="text"
                  value={prompt.negative}
                />
                <SilboSendButton
                  active={prompt.positive.length || prompt.negative.length > 0}
                  activePosition={
                    positiveFocus && positiveTextareaOverflow
                      ? "14.5rem"
                      : undefined
                  }
                  onClick={
                    prompt.positive.length === 0
                      ? undefined
                      : () => onSend(prompt)
                  }
                />
              </div>
            </div>
          </div>

          <div
            className="silbo-message-input-container"
            style={{ position: "fixed", bottom: "-100px" }}>
            <textarea
              className="silbo-message-input"
              readOnly
              ref={messageTextAreaRef}
              value={prompt}
            />
          </div>
        </div>
      ) : null}

      {showPromptManager ? (
        <PromptManager
          isForImages={true}
          onClose={() => setShowPromptManager(false)}
          onSelected={(prompt) => onPromptLibrarySelected(prompt)}
        />
      ) : null}

      <HistorySideBar maxWidth={maxWidth}>
        <div
          className="d-flex justify-content-between"
          style={{
            flexWrap: !sideBarIsOpen ? "wrap" : undefined,
          }}>
          <OverlayTrigger
            overlay={
              <Tooltip>
                <p className="tooltip-text">
                  {sideBarIsOpen ? "Cerrar barra lateral" : "Abrir historial"}
                </p>
              </Tooltip>
            }
            placement={sideBarIsOpen ? "bottom" : "right"}>
            <div
              className="history-container"
              onClick={() => {
                dispatch(
                  setMaxWidth(sideBarIsOpen ? minWidthValue : maxWidthValue),
                );
              }}>
              <img alt="history" height={18} src={HistoryImage} width={18} />
              {sideBarIsOpen ? (
                <h5 className="session-title">Historial</h5>
              ) : null}
            </div>
          </OverlayTrigger>

          <OverlayTrigger
            overlay={
              <Tooltip>
                <p className="tooltip-text">Nueva generacion</p>
              </Tooltip>
            }
            placement={sideBarIsOpen ? "bottom" : "right"}>
            <div
              className={`history-container ${sideBarIsOpen ? "mr-3" : ""}`}
              onClick={() => {
                setImageList([]);
                navigate("/images");
              }}>
              <img
                alt="pencil"
                className="image-icon"
                height={18}
                src={pencilIcon}
                width={18}
              />
            </div>
          </OverlayTrigger>
        </div>
        <div className="sessions-container">
          {sideBarIsOpen &&
          !!getUserResult.data &&
          getUserResult.data?.user.imageSession?.length > 0 &&
          sasToken
            ? Object.keys(groupedData).map((date) => (
                <div key={date}>
                  <h2 className="group-date">{getSideBarDate(date)}</h2>
                  {groupedData[date].map((session) => (
                    <div
                      className="session-container"
                      key={session.session_id}
                      onClick={() => {
                        navigate(`/images/${session.session_id}`);
                      }}
                      style={{
                        backgroundColor:
                          session.session_id === params.id
                            ? "#F4F4F4"
                            : undefined,
                      }}>
                      {session.images.length === 2 ? (
                        <div className="image-session-container">
                          <Image
                            alt="image-preview"
                            className="image-preview"
                            height="4.3125rem"
                            src={`${session.images[0].value}?${sasToken}`}
                          />
                          <Image
                            alt="image-preview"
                            className="image-preview"
                            height="4.3125rem"
                            src={`${session.images[1].value}?${sasToken}`}
                          />
                        </div>
                      ) : (
                        <div className="image-session-container one-item">
                          <Image
                            alt="image-preview"
                            className="image-preview"
                            height="4.3125rem"
                            src={`${session.images[0].value}?${sasToken}`}
                          />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              ))
            : null}
        </div>
        <UserInfo
          isStatic={true}
          style={
            sideBarIsOpen
              ? undefined
              : { marginLeft: "-0.60rem", maxWidth: "4rem", padding: ".75rem" }
          }
        />
      </HistorySideBar>

      {isDesktop() &&
      selectedModel &&
      selectedModel.type !== IMAGES_TYPES.EDITOR ? (
        <div
          className="prompt-library-container"
          onClick={() => setShowPromptManager(true)}
          role="button">
          <div className="silbo-button-container">
            <PromptLibraryImage />
          </div>
          <p className="pt-2">Prompt Library</p>
        </div>
      ) : null}
    </div>
  );
};
