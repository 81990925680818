import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import React from "react";

import Card1 from "@/assets/img/fundador/Card_1.png";
import Card2 from "@/assets/img/fundador/Card_2.png";
import Card3 from "@/assets/img/fundador/Card_3.png";

import CardApp from "@/components/CardApp/CardApp";

import { setProfile } from "@/redux/store";

import { SILBO_PROFILES } from "@/utils";

const FundadorHomeContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <Row className="row-container mb-3">
      <Col xs={4}>
        <CardApp
          available={true}
          image={Card1}
          onClick={() => {
            navigate("/general");
          }}
          text="FUNDADOR Copywriter"
        />
      </Col>
      <Col xs={4}>
        <CardApp
          available={true}
          image={Card2}
          onClick={() => {
            navigate("/images");
          }}
          text="FUNDADOR images"
        />
      </Col>
      <Col xs={4}>
        <CardApp
          available={true}
          image={Card3}
          onClick={() => {
            dispatch(setProfile(SILBO_PROFILES.SILBO_AD_CREATOR));
            navigate(SILBO_PROFILES.SILBO_AD_CREATOR, { replace: true });
          }}
          text="FUNDADOR Ad Creator"
        />
      </Col>
    </Row>
  );
};
export default FundadorHomeContent;
