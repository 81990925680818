import { Dropdown, DropdownButton } from "react-bootstrap";

import "./ProductSelector.css";

const ProductSelector = ({ products, selectedProduct, setSelectedProduct }) => (
  <div className="product-selector d-flex gap-3 align-items-center">
    <p>Producto:</p>
    <DropdownButton
      className="product"
      drop="up-centered"
      title={selectedProduct.name}
      value={selectedProduct.id}
      variant="light">
      {products.map((product) => (
        <Dropdown.Item
          key={product.id}
          onClick={() => setSelectedProduct(product)}
          style={{ width: "100%" }}
          value={product.id}>
          {product.name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  </div>
);
export default ProductSelector;
