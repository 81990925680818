// NOTE: INIT This makes slower
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { useEffect, useRef, useState } from "react";

import sampleVisual1 from "@/assets/img/ad_creator/sample_visual_1.svg";
import sampleVisual2 from "@/assets/img/ad_creator/sample_visual_2.svg";

import SilboAdCreatorFooter from "@/components/Ad_creator/Footer";
import { SilboAdCreatorStepBar } from "@/components/Ad_creator/StepBar";
import HistoryItem from "@/components/HistoryItem";

// NOTE: END This makes slower
import {
  setAdCreatorStepNext,
  setData,
  setIsForRegeneration,
} from "@/redux/store";
import { LOADING_V2_MODE, setLoadingV2, setLoadingV2Mode } from "@/redux/store";

export const SilboAdCreatorStep5Visuals = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.adCreator.data);
  const session = useSelector((state) => state.adCreator.session);
  const visualImageListThumbnailRef = useRef([]);
  const [visualImagePreview, setVisualImagePreview] = useState({
    img_1: null,
    img_2: null,
  });
  const fakeMode = useSelector((state) => state.main.fakeMode);

  const onClick = async (e, visual_image_selected) => {
    e.stopPropagation();

    // Launch Loading
    dispatch(setLoadingV2(true));
    // After a while, jump to next screen
    setTimeout(() => dispatch(setAdCreatorStepNext()), 1000);

    if (fakeMode) {
      // Remove loading
      setTimeout(() => {
        dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST));
      }, 1000);
      return;
    }

    // Call selectconcept
    const body = { template: "preview", img: visual_image_selected };
    const response = await axios.post(`/generatecreative/${session}`, {
      ...body,
    });

    // Store responses
    const dataLocal = JSON.parse(JSON.stringify(data));
    dataLocal.visual_image_selected = visual_image_selected;
    dataLocal.download_urls = response.data.download_urls;
    dispatch(setData(dataLocal));
    //dispatch(setLoadingV2(false))
    dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST));
  };

  const onRegenerate = async () => {
    // Launch Loading
    dispatch(setIsForRegeneration(true));
    dispatch(setLoadingV2(true));

    // Call selectconcept
    const dataItem = data.elaborate_insight_selected;
    const body = { ...dataItem };
    const response = await axios.post(`/selectconcept/${session}`, { ...body });

    // Store responses
    const dataLocal = JSON.parse(JSON.stringify(data));
    dataLocal.visual_image_list.push({ ...response.data });
    dispatch(setData(dataLocal));
    dispatch(setLoadingV2Mode(LOADING_V2_MODE.FAST));
    dispatch(setIsForRegeneration(false));
  };

  useEffect(() => {
    const DEFAULT_IMAGE_LIST = [
      { img_1: sampleVisual1, img_2: sampleVisual2 },
      { img_1: sampleVisual1, img_2: sampleVisual2 },
      { img_1: sampleVisual1, img_2: sampleVisual2 },
      { img_1: sampleVisual1, img_2: sampleVisual2 },
    ];
    let visual_image_list =
      data.visual_image_list.length > 0
        ? data.visual_image_list
        : DEFAULT_IMAGE_LIST;
    // visual_image_list.pop()
    visualImageListThumbnailRef.current = visual_image_list;
    setVisualImagePreview(visual_image_list[visual_image_list.length - 1]);
  }, [data.visual_image_list]);

  const onClickHistory = (idx) => {
    setVisualImagePreview(visualImageListThumbnailRef.current[idx]);
  };

  return (
    <div className="d-flex flex-column silbo-ad-creator-container">
      <SilboAdCreatorStepBar step={props.step} />
      <div
        className="mt-2 flex-grow-1 bg-white d-flex flex-column"
        style={{ borderRadius: "10px", padding: "2em 3em" }}>
        <div className="flex-grow-1 position-relative">
          {visualImageListThumbnailRef.current.length > 0 ? (
            <div
              className="position-absolute"
              style={{
                height: 0,
                minHeight: "100%",
                width: "10%",
                overflowY: "scroll",
              }}>
              {visualImageListThumbnailRef.current.map((item, idx) => (
                <HistoryItem
                  className="mb-3"
                  img_1={item.img_1}
                  img_2={item.img_2}
                  key={idx}
                  onClick={() => onClickHistory(idx)}
                />
              ))}
            </div>
          ) : null}

          <div>
            <div
              className="fw-bold text-center w-75"
              style={{ fontSize: "32px", margin: "0 auto" }}>
              {t("step_5_visuals_large_title")}
            </div>
            <div
              className="mt-4 d-flex justify-content-center w-75 flex-grow-1"
              style={{ margin: "0 auto" }}>
              <div className="pe-1 w-50">
                <img
                  alt=""
                  className="border w-100"
                  src={visualImagePreview.img_1}
                />
                <div
                  className="silbo-button w-100 mt-3 p-3 "
                  onClick={(e) => onClick(e, visualImagePreview.img_1)}
                  role="button">
                  {t("select")}
                </div>
              </div>
              <div className="ps-1 w-50">
                <img
                  alt=""
                  className="border w-100"
                  src={visualImagePreview.img_2}
                />
                <div
                  className="silbo-button w-100 mt-3 p-3"
                  onClick={(e) => onClick(e, visualImagePreview.img_2)}
                  role="button">
                  {t("select")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <SilboAdCreatorFooter onRegenerate={() => onRegenerate()} />
        </div>
      </div>
    </div>
  );
};
