import { useState } from "react";

export const ASPECT_RATIO = {
  RATIO_4_5: "4:5",
  RATIO_2_3: "2:3",
  RATIO_16_9: "16:9",
  RATIO_1_1: "1:1",
};

const AspectRatioSelector = (props) => {
  const [aspectRatio, setAspectRatio] = useState(ASPECT_RATIO.RATIO_16_9);

  const onClick = (aspect_ratio) => {
    if (props.onChange) props.onChange(aspect_ratio);
    setAspectRatio(aspect_ratio);
  };

  return (
    <div className="d-flex align-items-center gap-2 aspect-ratio">
      <p className="mb-0 ratio-container">Aspect Ratio:</p>
      <div
        className={`option ${aspectRatio === ASPECT_RATIO.RATIO_4_5 && "active"}`}
        onClick={() => onClick(ASPECT_RATIO.RATIO_4_5)}
        role="button">
        {ASPECT_RATIO.RATIO_4_5}
      </div>
      <div
        className={`option ${aspectRatio === ASPECT_RATIO.RATIO_2_3 && "active"}`}
        onClick={() => onClick(ASPECT_RATIO.RATIO_2_3)}
        role="button">
        {ASPECT_RATIO.RATIO_2_3}
      </div>

      <div
        className={`option ${aspectRatio === ASPECT_RATIO.RATIO_16_9 && "active"}`}
        onClick={() => onClick(ASPECT_RATIO.RATIO_16_9)}
        role="button">
        {ASPECT_RATIO.RATIO_16_9}
      </div>
      <div
        className={`option ${aspectRatio === ASPECT_RATIO.RATIO_1_1 && "active"}`}
        onClick={() => onClick(ASPECT_RATIO.RATIO_1_1)}
        role="button">
        {ASPECT_RATIO.RATIO_1_1}
      </div>
    </div>
  );
};
export default AspectRatioSelector;
