import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";

import FeedBack from "@/components/Feedback/Feedback";
import UserInfo from "@/components/UserInfo/UserInfo";
import { VideoPlayer } from "@/components/VideoPlayer/VideoPlayer";

import theme from "@/branding/theme";

import "./Home.css";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="container pt-4 pb-4">
      <h2 className="title">{t("home_title")}</h2>
      <section className="home-main-section">
        <div className="main-content">
          <p className="main-content-text">{t("main-content-text")}</p>
          <VideoPlayer />
        </div>
        <div className="image-container">
          {theme().home.mainImage ? (
            <img
              alt="main"
              className="main-image"
              src={theme().home.mainImage.default}
            />
          ) : null}
          {theme().home.homeAppsGroup ? (
            <img
              alt="home-apps-group"
              className="main-image-group"
              src={theme().home.homeAppsGroup.default}
            />
          ) : null}
        </div>
      </section>

      <h3 className="home_secondary">{t("home_secondary_title")}</h3>
      <p className="home_secondary_subtitle">{t("home_secondary_subtitle")}</p>

      <Container>{theme().home.content()}</Container>

      <UserInfo />
      <FeedBack />
    </div>
  );
};
export default Home;
